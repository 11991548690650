/*
    ** TEAM PAGE
*/

.teams {
    .team-item {
        padding:30px 0;
        position:relative;
        margin-top:40px;
        ._cover;

        &:nth-child(1) {
            margin-top:0;
        }

        .bg-white {
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            background:rgba(255,255,255,0.5);
            z-index:10;
        }

        .team-txt {
            position:relative;
            z-index:11;
            background-color:rgba(255,255,255,0.85);
            border-bottom:5px solid @blue;
            padding:20px;

            h2 {
                font-size:2.2rem;
                margin-bottom:15px;
                color:@blue;
            }

            blockquote {
                padding-left:20px;
                margin-top:0;
            }

            .body {
                font-size: 2rem;
                color:black;
            }
        }
    }


    ._media-s-up({
        .team-item {
            padding:70px 0;

            .team-txt {
                h2 {
                    margin-bottom:20px;
                }

                blockquote {
                    padding-left:20px;
                }
            }
        }
    });
}
