/*! Flickity v2.0.11
http://flickity.metafizzy.co
---------------------------------------------- */
/*! Flickity v2.1.0
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
          tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: white;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: #333;
}



.flickity-enabled {
    /* ---- previous/next buttons ---- */
    .flickity-prev-next-button {
        ._reset;
        position: absolute;
        top: 0%;
        width: 33%;
        height: 100%;
        display: block;
        border: none;
        cursor: pointer;
        color: white;
        background-color:transparent;
        ._transition;


        // Statements
        &:hover {
            border-width: 0px;
            background-color: transparent;

            svg {
                .arrow {
                    fill:@blue
                }
            }
        }
        &:focus {
            outline: none;
        }
        &:active {
            opacity: 1;
        }
        &:disabled {
            opacity: 0;
            cursor: auto;
        }

        // Previous
        &.previous {
            left: 0;
            text-align: left;
            padding-left:25px;
        }

        // Next
        &.next {
            right: 0;
            text-align: right;
            padding-right:25px;
        }

        // svg
        svg {
            width: 45px;
            height: 45px;

            .arrow {
                fill: white;
                ._transition;
            }
        }
    }


    /* ---- page dots ---- */
    .flickity-page-dots {
        ._reset;
        position: absolute;
        width: 100%;
        bottom: -35px;
        list-style: none;
        text-align: center;
        line-height: 1;

        .dot {
            display: inline-block;
            width: 16px;
            height: 16px;
            margin: 0 5px;
            background: #c5c5c5;
            border-radius: 50%;
            cursor: pointer;
            ._transition;

            &.is-selected {
               background-color:white;
            }
        }
    }
}
