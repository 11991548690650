// Not enough stock & not in stock modal, display warning modal for accepting conditions
#not-enough-stock {
    .modal-body {
        form {
            label {
                font-size:0.84em;
            }
        }
    }
}

#not-enough-stock,
#not-enough-stock-confirmation {

    .modal-header {
        color:#000;
        font-size:1.57em;
        text-transform:none;
        font-weight:700;
        border:0;
    }

    .modal-body {

        .alert {
            position:relative;
            margin-top:0;
            padding-left:70px;

            &:before {
                display:block;
                position:absolute;
                top:20px;
                left:20px;
                font-size:2em;
            }
        }

        form {
            .form-item {
                margin-right:0;
            }
            label {
                display:block;

                &.error,
                &.is-invalid {
                    padding:15px;
                    border:1px solid @red;
                    border-left-width:4px;
                    background-color:#ffeaea;
                }

                p {
                    display:inline;
                }
            }

            button {
                height:55px;
                padding:0 20px;
                margin-bottom:20px;
                text-transform:none;

                i {
                    display:none;
                }
            }

            .btn {
                font-size:0.84em;
            }
        }
    }

    ._tablet({
        .modal-content {
            width:90%;
        }
    });

    ._phone({
        .modal-body {
            .alert {
                padding:15px;

                &:before {
                    display:none;
                }
            }
        }
    });
}


// Success "add to cart" action, show "where to go" modal
#success-order {
    .modal-footer {
        .go-to-cart {
            font-size:2rem;
            line-height:2em;
            font-weight:700;
        }

        ._tablet({
            .column {
                padding:20px 0;

                a {
                    display:block;
                }

            }

        });
    }
}

// Product detail
#product-detail {
    .modal-content {
        .cc-inside {
            width:100%;
            font-size:0.9em;

            .breadcrumb {display:none;}

            h1 {
                font-size:1.4em;
                line-height:1.4;
            }
        }
    }

    ._tablet({
        .modal-content {
            width:90%;
        }
    });
}

// EZICUT
#ezicut {
    .modal-body>.cc-inside {
        width:100%;
    }

    ._tablet({
        .modal-content {
            width:90%;
        }
    });
}

// Send cart by mail
#cartName,
#sendCartByMail,
#confirmAskOffer {
    .form-item {
        ._reset;

        label {
            ._reset;
            line-height:50px;
            font-size:1.1em;
            color:@text-mc;
        }
        input {
            display:block;
            width:100%;
        }
        select {
            display:block;
            width:100%;
        }
        button {
            display: block;
            width: 100%;
            padding: 7px 10px;
        }
    }

    .message a {
        text-decoration:none;
    }

    ._tablet({
        .modal-content {
            width:90%;
        }
    });
}

#confirmAskOffer {
    .form-item {
        margin: .8em 0;
    }
}

// Quick add favorites products
#quick-add-product {
  form {
    button,
    input,
    select {
      width: 100%;
      min-width: 100%;
      display: block;
    }
  }

  .description {
    font-style: italic;
    font-size: 1.4rem;
    color: @red;
    padding: 5px 0;
  }

  span.select2 {
    width: 100% !important;

    span.select2-selection {
      display: block;
      padding: 0 0 0 1em;
      height: @input-lh;
      border: 1px solid @input-bc;
      vertical-align: middle;
      font-size: @input-fs;
      width: 100%;
      max-width: 100%;
      margin-bottom: 0;
      ._box-shadow('inset 0px 1px 2px 0px rgba(0,0,0,0.1)');
      ._border-radius(unit(@input-br, px));
      ._transition;

      /* Hack IE8 & < */
      height: inherit \9;
      padding: 0.8em 0.3em 0.8em 1em \9;

      &:focus {
        outline: none;
        border-color: @form-mc;
      }

      span.select2-selection__rendered {
        line-height: 54px;
        padding: 0;
      }

      span.select2-selection__arrow {
        height: 47px;
        b {
          border-color: #131313 transparent transparent;
          border-width: 6px 3px 0;
        }
      }

      span.select2-selection__clear {
        padding: 2px;
        font-weight: 0;
        font-size: 25px;
        left: -28px;
        top: -6px;
      }
    }
  }
}

// Modal Téléchargement des raccourcis
#download_modal,
#download_shortcuts {
    p,ul {
        font-size: 1.7rem;
    }

    p {
        padding-top: 30px;

        ._phone({
            padding-top: 0;
        });
    }

    span.cc-blue {
        font-size: 1.9rem;
        color:@blue;
    }

    .alert-success {
        display:none;
    }

    form {
        button,
        input {
            display: block;
            width: 100%;
        }
    }
}
