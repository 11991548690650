/* ******** */
/** IMAGES **/
/* ******** */

/* ********************* */
img {
    max-width:100%;
    height:auto;

    &.cc {
        /* No responsive images */
        &-no-responsive {
            max-width:inherit;
        }

        /* Bordered */
        &-bordered {
            ._inline;
            background-color:white;
            padding:5px;
            border:1px solid @gray-li;
            ._border-radius(4px);
        }

        /* Shadowed image */
        &-shadowed {
            ._inline;
            ._box-shadow('0px 0px 8px 0px rgba(0,0,0,0.4)');
        }

        /** Experimental | removed to ChuckCSS V1 **/
        /* Reset filter on hover */
        // &-no-hover {
        //     ._transition;

        //     &:hover {

        //         ._filter(sepia(0));
        //         ._filter(grayscale(0));
        //         ._filter(blur(0px));
        //     }

        // }

        // // Filters
        // &-sepia {
        //     ._filter(sepia(1));

        //     &-hover {
        //         ._transition;
        //         &:hover {
        //             ._filter(sepia(1));
        //         }
        //     }
        // }
        // &-grayscale {
        //     ._filter(grayscale(1));

        //     &-hover {
        //         ._transition;
        //         &:hover {
        //             ._filter(grayscale(1));
        //         }
        //     }
        // }
        // &-blur {
        //     ._filter(blur(5px));

        //     &-hover {
        //         ._transition;
        //         &:hover {
        //             ._filter(blur(5px));
        //         }
        //     }
        // }
    }
}
