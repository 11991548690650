/* ********** */
/** MESSAGES **/
/* ********** */

/* ********************* */

/*
    - General messages styles
    - types : warning, error, success, info

    <div class="message message-{type}">
        ...
    </div>
*/

.message {
    padding: 0.5em 1em;
    border: 1px solid transparent;
    border-left-width:7px;
    margin:1.5em 0;
    position:relative;

    /* Links inside a .message element */
    a {
        text-decoration:underline;
    }

    /* List : add margin */
    ul {
        width:90%;
        margin:1em auto;
    }

    /* Messages types */
    &-primary {
        border-color:@primary;
        color:@primary;

        p,div,ul,
        button.close,
        a {
            color:@primary;
        }
    }
    &-info {
        border-color: @alert-in-bc;
        color:@alert-in-tc;

        p,div,ul, a {
            color:@alert-in-tc;
        }
    }
    &-warning {
        border-color: @alert-wa-bc;
        color:@alert-wa-tc;

        p, div, ul, a {
            color:@alert-wa-tc;
        }
    }
    &-error {
        border-color: @alert-er-bc;
        color:@alert-er-tc;

        p, div, ul, a {
            color:@alert-er-tc;
        }
    }
    &-success {
        border-color: @alert-su-bc;
        color:@alert-su-tc;

        p, div, ul, a {
            color:@alert-su-tc;
        }
    }
}
