/* Contact page */
.contact {

    .contact-methods {
        .column {
            margin-top:15px;
            margin-bottom:15px;
            text-align:center;
        }

        .icon {
            ._inline;
            height:100px;
            width:100px;
            line-height:100px;
            color:white;
            background-color:@blue;
            ._border-radius(100%);
            text-align:center;
            font-size:2.1em;

            i:before {
                ._reset;
            }
        }

        h2 {
            text-transform:none;
            margin:15px 0;
        }

        .info {
            font-size:1.05em;
        }
    }

    .contact-form {
        padding:30px 0 50px;

        input,textarea,select {
            width:100%;
        }

        textarea {
            height:190px;
        }

        button {
            padding:0 50px;
        }

        .form-actions {
            text-align:center;
        }

        & > div > div {
            margin:20px 0;
        }
    }
}
