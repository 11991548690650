/* TELECHARGEMENTS
*/

.downloads {

    .downloads-listing {
        padding:30px 0;
    }

    .download-category {
        padding:60px 0 0;

        &:nth-child(1) {
            padding-top: 0px;
        }

        h2 {
            color:@blue;
            font-weight: 400;
            text-transform: uppercase;
            font-size: 1.8rem;
            margin-bottom: 20px;
        }
    }

    .category-files {

        // Each DL item
        .attachment {
            margin:0 0 20px;

            a {
                background-color:#f0f0f0;
                padding:15px 20px;
                border-radius: 5px;
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                text-transform: uppercase;
                color:#333333;
                font-size: 1.6rem;
                ._transition;

                i {
                    margin-left:20px;
                    font-size:2.4rem;
                    color:#9b9b9b;
                    ._transition;

                    &:before {
                        ._reset;
                        display: block;
                    }
                }

                &:hover {
                    background-color:@blue;
                    color:white;

                    i {
                        color:white;
                    }
                }
            }

            .attachment-description {
                margin-top: 15px;
                font-weight: 400;
                color: #6a6a6a;
                font-style: italic;
                font-size: 1.6rem;
            }
        }
    }

    // Screen > 1999px
    ._media-l-up({

        .category-files {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            // Each DL item
            .attachment {
                flex:0 0 48%;
                max-width:48%; // Hack IE :/
            }
        }

    });
}
