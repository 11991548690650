body:has(#generic-landing-container){
    #content{
        flex-grow: 1;
        display: flex;
    }
    #main-footer{
        margin:0;
    }
}

#generic-landing-container{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

#generic-landing-header-section{
    padding: 3.125rem;
    @media (max-width: 768px) {
        padding: 2.75rem 1.25rem;
    }
}

#generic-landing-product-images{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1.23rem;
    img{
        height: 3.75rem;
        width: auto;
    };
}

#generic-landing-h1{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    padding-bottom: 0!important;
    text-transform: uppercase;
    &::after{
        content: none;
    }
}

#generic-landing-ref{
    font-size: 2.875rem;
    color: #003E67;
    @media (max-width: 768px) {
        font-size: 2rem;
    }
}

#generic-landing-title{
    font-size: 2.875rem;
    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
}

#generic-landing-buttons-section{
    background-color: #0062A6;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
}

.generic-landing-button{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 2px solid white;
    color: #0062A6;
    font-size: 1.75rem;
    width: 21.875rem;
    height: 5.375rem;
    padding: .75rem;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 0;
    line-height: 1em;
    transition: color .2s ease-in-out,  background-color .3s ease-in-out;
    &:not(:last-child){
        margin-bottom: 3rem;
    }
    &:hover{
        background-color: transparent;
        color: white;
    }
}

#generic-landing-inquiries{
    color: white;
    margin-bottom: 2rem;
    max-width: 60ch;
    text-align: center;
    text-wrap: balance;
    border-top: 2px solid white;
    padding-top: 3rem;
}
