/* ******** */
/** TITLES **/
/* ******** */

/* ********************* */

h1,.h1-like,
h2,.h2-like,
h3,.h3-like,
h4,.h4-like,
h5,.h5-like,
h6,.h6-like {
    margin: 0;
    font-weight:400;
    color:@title-mc;

    small {
        font-weight:400;
        color:@subtitle-mc;
        font-size:@subtitle-fs;
    }
}

.h1-like,
h1 {
    font-size:@title-h1-fs;
    line-height:@title-h1-lh;
}
.h2-like,
h2 {
    font-size:@title-h2-fs;
    line-height:@title-h2-lh;
}
.h3-like,
h3 {
    font-size:@title-h3-fs;
    line-height:@title-h3-lh;
}
.h4-like,
h4 {
    font-size:@title-h4-fs;
    line-height:@title-h4-lh;
}
.h5-like,
h5 {
    font-size:@title-h5-fs;
    line-height:@title-h5-lh;
}
.h6-like,
h6 {
    font-size:@title-h6-fs;
    line-height:@title-h6-lh;
}
