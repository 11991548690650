// Homepage
.homepage {

  // Big slider on Homepage with background videop
  .homepage-slider {
    width: 100%;
    position: relative;
    height:100vh;


    // Overlay
    .overlay-blue {
      position: absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      z-index:5;
      background:rgba(0,98,166,0.25);
    }

    .carousel {
      position: relative;
      z-index:9;
      height:100%;
      width: 100%;

      .carousel-cell {
        height:100%;
        width: 100%;
        display: flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        color:white;

        h2,
        h3,
        p {
          color:white;
          text-shadow:1px 0px 3px rgba(0,0,0,0.7);
          margin:10px;
          text-align: center;
          padding:0 20px;
          max-width:100%; // Hack IE :/
        }

        h2 {
          font-size: 3.8rem;
          font-weight: 700;
        }

        h3 {
          font-size: 3rem;
        }

        p {
          font-size: 1.9rem;


          a {
            display: inline-block;
            line-height:5rem;
            background-color:@blue-da;
            color:white;
            padding:0 50px;
            ._transition;

            &:hover {
              background-color:@blue;
            }
          }
        }
      }

      .flickity-button {
        display: none;
      }
    }
  }

  // Swiss made logo
  .homepage-swissmade {
    background-color:#042147;

    img {
      display: block;
      margin: 0 auto;
      max-width: 160px;
    }
  }

  // News
  .homepage-news {
    background-color:#f0f0f0;
    padding:50px 0;

    .wrap-home-news {

      .home-news-img {
        img {
          display: block;
          margin:0 auto 20px;
        }
      }

      .home-news-text {
        h2 {
          color:@redswiss;
          font-size: 2.5rem;
          margin-bottom:20px;
          text-align: center;
        }

        p {
          font-size: 1.8rem;
          font-weight: 400;
          color:black;

          a {
            color:@blue;
          }
        }
      }
    }
  }

  // Intro texts
  .homepage-intro {
    padding:50px 0;
    text-align:center;

    .cms-intro {
      font-weight: 400;
      color:black;
    }
  }

  // PRODUCTS FAMILIES
  .homepage-products-families {
    background-color: #0062AA;
    min-height: calc(~"100vh - 80px");
    display: flex;
    flex-direction: column;
    padding: 50px 0;
    &.bg-white {
      background-color: white;
      color: #003e67;
      h1 {
        color: #333;
      }
      h1::after{
        background-color: #0062aa;
      }
    }

    h1{
      color: white;
      margin-bottom: 8px;
    }
    h1::after{
      background-color: white;
      margin: 15px auto 0px;
    }

    .intro-family-products {
      &.hide {
        display: none;
      }

      color: white;
      max-width:1200px;
      margin: 0 auto 40px;

      h2 {
        color:white;
        font-size: 2.8rem;
        text-align: center;
        font-weight: 300;

        &:after {
          display: block;
          content:' ';
          width:125px;
          height:1px;
          background-color:white;
          margin:15px auto 40px;
        }
      }

      div {
        color:white;
        text-align:center;
        font-size: 1.8rem;
        font-weight: 400;
      }
    }

    .after-family-products{
      &.hide {
        display: none;
      }

      color: white;
      text-align: justify;
      max-width: 1200px;
      margin: 0 auto;
      font-weight: 400;
    }

    .wrap-families-pushes {
      padding:0;
      max-width: 1199px;
      margin: 0 auto 50px auto;
      .item {
        display: none;
        padding:10px;
        border-radius: 500px;
        margin-bottom: 10px;

        &.show {
          display: flex;
        }

        &:nth-child(n+6){
          .family-title {
            order:1;
            margin-bottom: 0;
            margin-top: 15px;
            align-items: flex-start!important;
          }
        }

        .item-container {
          height:100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content:center;
          flex-direction: column;
          position: relative;

          &:hover {
            .family-links {
              opacity: 1;
            }
          }

          .family-title {
            position: relative;
            display: block;
            margin-bottom: 15px;
            flex-grow: 1;
            display: flex;
            align-items: flex-end;
            h3 {
              position: relative;
              z-index:3;
              color:white;
              font-weight: 500;
              font-size: 2.4rem;
              padding:0 20px;
              text-align: center;
              text-transform: uppercase;
            }
          }


          .family-img{
            display: block;
            position: relative;
            height: 190px;
            width:190px;
            min-height: 190px;
            min-width:190px;
            background-size: cover;
            border-radius: 500px;
            &::after{
              content: "\f002";
              font-family: FontAwesome;
              position: absolute;
              color: white;
              font-size: 5rem;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
              width: 100%;
              border-radius: 500px;
              background-color: rgba(0, 0 ,0, .5);
              opacity: 0;
              transition: opacity .2s ease-in-out;
            }
            &:hover{
              &::after{
                opacity: 1;
              }

            }
          }

          .family-links {
            opacity: 0;
            position: absolute;
            bottom:48px;
            left: 0;
            right: 0;
            text-align: center;
            ._transition;

            a {
              display: inline-block;
              vertical-align: top;
              background-color:@blue;
              color:white;
              line-height: 2.9rem;
              padding:0 20px;
              text-transform: uppercase;
              margin:0 5px;
              font-size: 1.4rem;
              ._transition;

              &:hover {
                background-color:@blue-da;
              }
            }
          }
        }
      }

      .gamme-wrapper {
        min-height: calc(~"100vh - 80px");
        width: 100%;
        display: none;
        flex-direction: column;
        position: relative;
        section.hide{
          display:none;
        }
        &.show {
          display: flex;
        }
      }
      .family-back {
        text-transform: uppercase;
        color: #003e67;
        margin-bottom: 8px;
      }
      .wrap-cms-intro {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 0 50px 0 50px;
        margin-bottom: 40px;
      }

      .wrap-accordeons-bloc {
        padding: 0;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        .gamme-zones {
          display: flex;
          flex-wrap: wrap;
          flex-grow:1;
          .gamme-zone {
            display: none;
            justify-content: center;
            padding: 10px;
            border-radius: 500px;
            margin-bottom: 10px;
            flex-grow: 1;
            flex: 0 0 100%;
            max-width: 100%;
            &.show {
              display: flex;
            }

            .gamme-zone-container {
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              position: relative;
            }

            .gamme-title {
              position: relative;
              display: block;
              margin-bottom: 15px;
              flex-grow: 1;
              display: flex;
              align-items: flex-end;
              justify-content: center;
              h2 {
                position: relative;
                z-index: 3;
                color: #333;
                font-weight: 500;
                font-size: 2.4rem;
                padding: 0 20px;
                text-align: center;
                text-transform: uppercase;
                margin: 0;
              }
            }

            &:nth-child(n + 11) {
              .gamme-title {
                order: 1;
                margin-bottom: 0;
                margin-top: 15px;
                align-items: flex-start !important;
              }
            }

            .gamme-img {
              display: block;
              position: relative;
              height: 190px;
              width: 190px;
              min-height: 190px;
              min-width: 190px;
              background-size: cover;
              border-radius: 500px;
              border: 4px solid #003e67;
              &::after {
                content: "\f002";
                font-family: FontAwesome;
                position: absolute;
                color: white;
                font-size: 5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 100%;
                border-radius: 500px;
                background-color: rgba(0, 0, 0, 0.5);
                opacity: 0;
                transition: opacity 0.2s ease-in-out;
              }
              &:hover {
                &::after {
                  opacity: 1;
                }
              }
            }

            &.shown {
              h2 a i {
                ._rotate(-180deg);
              }
            }

            h2 {
              a {
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #333333;
                font-size: 2rem;

                i {
                  display: block;
                  margin-left: 20px;
                  ._transition;
                  ._rotate(0deg);

                  &:before {
                    ._reset;
                    display: block;
                  }
                }
              }
            }

            .gamme-zone-text {
              padding: 30px 0 0;
              font-weight: 400;
              color: black;
              font-size: 1.8rem;
              display: none;
              max-width: 100%;
              strong {
                font-weight: 500;
              }

              td {
                white-space: nowrap;

                // Link "Voir dans le Eshop"
                a.btn {
                  ._helvetica;
                  line-height: 1.2;
                }

                &:nth-child(2) {
                  min-width: 50px;
                  width: 50px;

                  img {
                    max-height: 20px;
                    max-width: inherit;
                    width: auto;
                  }

                  a {
                    display: flex;
                    padding: 3px 0px;
                    align-items: center;
                  }
                }
              }
            }
          }
          .sub-gamme-zone-slide {
            flex-grow:1;
            min-height: calc(~"100vh - 80px");
            background-color: white;
            z-index: 1000;
            display: none;
            &.show {
              display: block;
            }

            table {
              margin: 0;
              font{
                font-family: Helvetica, "Helvetica Neue", Arial, sans-serif!important;
              }
              tbody {
                tr {
                  cursor: pointer;
                  &:hover {
                    background-color: #f4f4f4;
                  }
                  &:first-child {
                    td {
                      border-top: 4px solid #003e67 !important;
                    }
                  }
                  &:last-child {
                    td {
                      border-bottom: 4px solid #003e67 !important;
                    }
                  }
                  td {
                    border-bottom: 1px solid #003e67 !important;
                    vertical-align: middle !important;
                    font-size: 2rem !important;
                    padding: 12px 8px;
                    &:nth-child(2){
                      min-width: 100px;
                    }
                    * {
                      line-height: 1;
                    }
                    a {
                      vertical-align: middle !important;
                    }
                    img {
                      vertical-align: middle !important;
                      mix-blend-mode: multiply;
                      max-height: 40px;
                      width: auto;
                    }
                    p {
                      text-align: left !important;
                      font-size: 2rem !important;
                    }
                    font {
                      font-size: 2rem !important;
                    }
                    &:first-child {
                      p font {
                        color: #003e67 !important;
                        font-weight: 600 !important;
                      }
                    }
                    &:last-child {
                      font-weight: 500 !important;
                      p {
                        text-align: right !important;
                      }
                      .btn.cc-bg-blue.external-link {
                        background-color: transparent !important;
                        border: none !important;
                        color: #003e67 !important;
                        padding: 0 !important;
                        i::before {
                          margin: 0;
                        }
                      }
                    }
                  }
                }
              }
            }

            .sub-gamme-title {
              position: relative;
              display: block;
              flex-grow: 1;
              display: flex;
              align-items: flex-end;
              justify-content: center;
              margin-bottom: 8px;
              h3 {
                text-align: center;
                text-transform: uppercase;
                font-size: 2.8rem;
                line-height: 1.2;
                color: #333;
                margin: 0;
                font-weight: 400;
                span {
                  display: block;
                  width: 75px;
                  height: 4px;
                  margin: 15px auto 0 auto;
                  background-color: #0062a6;
                }
              }
            }
            .gamme-zone-text {
              display: block;
              padding: 50px;
              .wrap-table {
                max-width: 1200px;
                margin: 0 auto;
                max-width: 100%;
                overflow-x: auto;
              }
            }
          }
        }
      }

      .gamme-empty {
        display: flex;
        justify-content: center;

        .gamme-content {
          font-weight: 400;
          color: black;
          display: block;
          //width: 1200px;
          margin: 0 auto;

          strong {
            font-weight: 500;
          }

          .css-ask-quotation {
            padding-top: 50px;

            a {
              padding: 0 30px;
              line-height: 5.2rem;
              text-transform: uppercase;
            }
          }
        }
      }
    }

    // Screen > 767px
    ._media-s-up({
      .gamme-zone {
        flex:0 0 50%!important;
        max-width:50%!important;
      }
      });

      // Screen > 989px;
      ._media-m-up({
        .gamme-image {
          flex:0 0 400px;
        }
        });


        // Screen > 1999px
        ._media-l-up({

          .gamme-zone {
            flex:0 0 20%!important;
            max-width:20%!important; // Hack IE :/
          }

          });

          @media (max-width:989px) {
            .gamme-empty {
              flex-wrap: wrap;
              .gamme-image {
                max-width: 400px;
              }
              .gamme-content {
                text-align: center;
              }
            }
          }



          @media (max-width:1199px) {
            .gamme-wrapper {
              min-height: calc(~"100vh - 68px");
              .gamme-zone {
                .gamme-title {
                  order: 1;
                  margin-bottom: 0;
                  margin-top: 15px;
                  align-items: flex-start !important;
                }
              }
            }
            .gamme-zone-slide {
              min-height: calc(~"100vh - 68px");
            }
          }

          .gamme-img-hover-box {
            display: none;
          }
          @media (hover: hover) {
            .gamme-img-hover-box-container {
              z-index: 100000;
              position: fixed;
              bottom: 75px;
              left: 0;
              max-width: 100%;
              width: 100%;
              padding: 0 50px;
              display: none;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              text-align: center;
              color: #003e67;
              .gamme-img-hover-box {
                background-color: white;
                margin: 0;
                display: block;
                max-width: 100%;
                width: 800px;
                border: 4px solid #003e67;
                box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
                padding: 20px;
                .gamme-img-hover-box-img {
                  height: 200px;
                }
              }
              &.show {
                display: flex;
              }
            }
          }


        }
      }

      //SECTEURS ACTIVITES
      .homepage-secteurs {
        padding:50px 0;

        .intro-secteurs {
          padding-bottom:50px;

          h2 {
            font-size: 2.8rem;
            text-align: center;
            font-weight: 300;
            color:@text-mc;

            &:after {
              display: block;
              content:' ';
              width:125px;
              height:1px;
              background-color:@text-mc;
              margin:15px auto 40px;
            }
          }

          div {
            text-align:center;
            font-size: 1.8rem;
            font-weight: 400;
            color:black;
          }
        }

        .wrap-secteurs {
          .item {
            padding:20px;

            .item-container {
              height:255px;
              max-height:255px;
              min-height:255px;

              a {
                position: relative;
                display: block;
                width: 100%;
                height:100%;

                &:hover {
                  h3 {
                    background-color:#005ba8;
                  }

                  &:before {
                    width:60%;
                    ._transition(0.15s, width, linear);

                  }

                  &:after {
                    height:60%;
                    ._transition(0.15s, height, linear, 0.15s);
                  }
                }

                h3 {
                  position: absolute;
                  bottom:0;
                  left:0;
                  right:0;
                  width:100%;
                  z-index:2;
                  color:white;
                  text-shadow:1px 0px 3px rgba(0,0,0,0.7);
                  font-weight: 300;
                  font-size: 1.8rem;
                  padding:20px;
                  text-align: center;
                  text-transform: uppercase;
                  background-color:fade(#005ba8, 75);
                  ._transition;
                }

                &:before {
                  display: block;
                  content: ' ';
                  width:0%;
                  height: 1px;
                  background-color: white;
                  position: absolute;
                  top:10%;
                  left:30%;
                  ._transition(0.15s, width, linear, 0.15s);
                }

                &:after {
                  display: block;
                  content: ' ';
                  width:1px;
                  height: 0%;
                  background-color: white;
                  position: absolute;
                  top:10%;
                  right:10%;
                  ._transition(0.15s, height, linear);
                }
              }
            }
          }
        }
      }


      // Screen > 767px
      ._media-s-up({
        // SLIDER VIDEO
        .homepage-slider {
          height:650px;

          .carousel {
            .carousel-cell {

              h2 {
                font-size: 5rem;
              }

              h3 {
                font-size: 3.8rem;
              }

              p {
                font-size: 2.3rem;
              }
            }
          }
        }

        // PRODUCTS FAMILIES
        .homepage-products-families {
          padding: 50px 0;
          .wrap-families-pushes {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .item {
              flex:0 0 50%;
              max-width:50%; // Hack IE :/

              .ua-ie & {

              }
            }
          }
        }

        // SECTEURS ACTIVITES
        .homepage-secteurs {
          .wrap-secteurs {
            display: flex;
            flex-wrap: wrap;

            .item {
              flex:0 0 50%;
              max-width:50%; // Hack IE :/
            }
          }
        }
        });


        // Screen > 989px
        ._media-m-up({
          // SLIDER VIDEO
          .homepage-slider {
            .carousel {

              .carousel-cell {
                h2,
                h3 {
                  padding:0 90px;
                }

                p {
                  max-width:60%;
                }
              }
              .flickity-button {
                display: block;
              }
            }
          }
          });

          // Screen > 1999px
          ._media-l-up({
            // News
            .homepage-news {
              .wrap-home-news {
                display: flex;
                justify-content: center;
                align-items:center;

                .home-news-img {
                  flex:0 0 500px;
                  max-width:500px; // Hack IE :/

                  img {
                    margin:0;
                  }
                }

                .home-news-text {
                  flex:1;
                  padding:0 0 0 50px;

                  h2 {
                    text-align: left;
                    font-size: 3.5rem;
                  }

                  p {
                    font-size: 2.5rem;
                    font-weight: 400;
                  }
                }
              }
            }

            // PRODUCTS FAMILIES
            .homepage-products-families {
              padding: 50px;
              .wrap-families-pushes {
                .item {
                  flex:0 0 33.32%;
                  max-width:33.32%; // Hack IE :/
                }
              }
            }
            // SECTEURS ACTIVITES
            .homepage-secteurs {
              .wrap-secteurs {
                .item {
                  flex:0 0 33.32%;
                  max-width:33.32%; // Hack IE :/
                }
              }
            }
            });

            @media (max-width:1199px){
              .homepage-products-families {
                min-height: 100vh;
              }
              .homepage {
                .item {
                  .family-title {
                    order:1;
                    margin-bottom: 0;
                    margin-top: 15px;
                    align-items: flex-start!important;
                  }
                }
              }
              .after-family-products{
                width: 728px;
              }
            }
            @media (max-width:989px){
              .homepage .homepage-products-families .wrap-families-pushes{
                .wrap-accordeons-bloc .gamme-zones .sub-gamme-zone-slide table tbody tr{
                  td{
                    font-size: 1.5rem !important;
                    p{
                      font-size: 1.5rem !important;
                      font{
                        font-size: 1.5rem !important;
                      }
                    }
                    font{
                      font-size: 1.5rem !important;
                    }
                  }
                }
                .wrap-cms-intro{
                  padding: 0 20px;
                  flex-direction: column;
                  .family-back{
                    align-self: flex-start;
                  }
                }
                .wrap-accordeons-bloc .gamme-zones .sub-gamme-zone-slide .gamme-zone-text{
                  padding: 50px 20px;
                }
              }
            }
            @media (max-width:767px){
              .after-family-products{
                width: 90%;
              }
            }
