// Eshop : Cart summary
.eshop.cart-summary {

    .nb-products {
        text-align:center;

        strong {
            color:@blue;
            font-size:1.26em;
        }
    }

    section {

        .cc-inside {
            border-top:3px dashed #a8a8a8;
            padding:50px 0;
        }

        h2 {
            text-align:left;
            margin-bottom:20px;
        }

        // 1. Recap panier
        &#cart-summary {
            .cc-inside {
                border:0
            }

            table.product-list {

                // Odd rows
                tr:nth-child(2n) {
                    td {
                        border-color:transparent white;
                    }
                }

                tr:last-child td {
                    border-bottom:4px solid @blue;
                }

                th {
                    background-color:@blue;
                    color:white;
                    font-weight:700;
                    font-size:0.9em;
                    vertical-align: middle;

                    span {
                        font-size:0.7em;

                        ._tablet({
                            display:block;
                            text-align: center;

                        });
                    }

                    &.total {
                        ._uppercase;
                    }

                    ._tablet({
                        &.cc-txt-right {
                            text-align:center;
                        }
                    });
                }

                td {
                    font-size:0.78em;
                    font-weight:700;
                    border-width:1px;
                    border-style: solid;
                    border-color:transparent #f2faff;
                    ._transition;

                    &.product {

                        // Images
                        &-images {

                            .product-images-name {
                                display: flex;
                                flex-wrap: wrap;
                                min-width: 350px;
                            }


                            .first {
                                flex: 0 0 150px;
                                max-width: 150px;

                                img {
                                    display:block;
                                    margin: 0 auto;

                                    & + img {
                                        margin-top: 5px;
                                    }
                                }
                            }

                            .second {
                                flex: 1;
                                align-self : center;
                            }
                        }

                        // Référence
                        &-reference {
                            span {
                                white-space: nowrap;
                            }
                        }

                        // Status
                        &-status {
                            text-align:center;

                            span.available {
                                ._inline;
                                height:18px;
                                width:18px;
                                ._border-radius(50%);
                                vertical-align: middle;
                            }
                        }

                        // Unit price
                        &-unit-price {
                            span {
                                color:@blue;
                                font-size:1.05em;
                                font-weight:700;
                                &.cc-red {
                                    color:@red;
                                }
                            }
                        }

                        // Qtty commandée
                        &-quantity {
                            input {
                                ._inline;
                                padding:0;
                                text-align:center;
                                height:35px;
                                max-width:90px;

                                ._tablet({
                                    font-size:1em;

                                });
                            }
                        }

                        // Quantité réelle livrée
                        &-quantity-delivery {

                        }

                        // Total price
                        &-total-price {
                            min-width:95px;

                            span {
                                font-size:1.2em;
                                font-weight:700;

                                // Tablet
                                ._tablet({
                                    font-size:1em;
                                });
                            }
                        }

                        //delete
                        &-delete {
                            a {
                                display:block;
                                margin:0 auto;
                                height:40px;
                                width:40px;
                                line-height:40px;
                                text-align:center;
                                color:@red;
                                font-size:1.4em;
                                ._border-radius(100%);
                                ._transition;

                                &:hover {
                                    color:white;
                                    background-color:@red;
                                }

                                i:before {
                                    ._reset;
                                }
                            }
                        }
                    }
                }

                ._tablet({
                    display:block;
                    overflow-x:auto;

                });
            }



            form .form-actions {
                margin-bottom:20px;
                display:none;

                &.shown {
                    display:block;
                }
            }

            .order-summary {

                .action-btns {
                    text-align:right;

                    a {
                        font-size: 1.7rem;
                    }

                    ._tablet({

                        a {
                            font-size: 1.9rem;

                            span {
                                display:none;
                            }

                            i:before {
                                ._reset;
                            }
                        }

                    });
                }

                // reset padding
                .column.columns {
                    padding:0;

                    &.total-order {
                        font-size:1.26em;
                        font-weight:700;

                        span {
                            font-size:0.8em;
                        }

                        .cc-right {
                            font-size:1.1em;
                        }
                    }

                    &.total-discount {
                        color:@green;
                        font-weight:700;

                        .cc-right {
                            font-size:1.1em;
                        }
                    }

                    &.total {
                        padding-top:20px;
                        font-size:1.36em;
                        font-weight:700;

                        p {
                            margin-left:auto;
                        }

                        .cc-right {
                            font-size:1.5em;
                            color:@blue;
                        }
                    }

                    // Tablet rules
                    ._tablet({
                        border-bottom:1px dashed #cecece;
                        padding:8px 0;

                        &.total {
                            padding:8px 0 0;
                            border:0;

                            .cc-right {
                                font-size:1.3em;
                            }
                        }
                    });
                }
            }

            // Phrase : "Hors TVA , frais de transport et autres frais si applicables"
            .warning {
                font-style:italic;
                color:#bbbbbb;
                text-align:right;
            }
        }

        // 2. Confirmer les addresses
        &#order-addresses {
            .address_form-item {
                &::before, &::after {
                    //content: "";
                    display: none;
                }
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;

                select {
                    min-width: 70%;
                }
            }
        }

        // 3. Ajouter un commentaire
        // 4. Ajouter des destinataires
        &#order-comment, &#order-reference,
        &#order-delivery {

            textarea {
                width:100%;
                height:130px;
                &#cart_validation_comment {
                    margin-top: 40px;
                }
            }

            input {
                width:100%;
                max-width:300px;
            }
        }

        // 6. Confirmation
        &#order-confirm {
            .form-checkbox {
                label {
                    font-size:0.84em;
                    margin-top:40px;
                    margin-bottom: 20px;

                    &.error,
                    &.is-invalid {
                        padding:15px;
                        border:1px solid @red;
                        border-left-width:4px;
                        background-color:#ffeaea;
                    }

                    &:nth-child(1) {
                        margin-top:0
                    }
                }
            }

            .form-item {
                margin-top:40px;

                a.fa-backward {
                    font-size:2rem;
                }
            }

            // Main columns container
            .final-steps {
                .column {
                    text-align: center;

                    button {
                        height: auto;
                        padding: 0.8em;
                        text-transform: none;
                    }

                    h3 {
                        margin-bottom: 20px;
                        color: lighten(@black, 45%);
                        border-bottom-color: lighten(@black,45%);
                        border-bottom-width: 3px;
                        font-weight: 500;
                        display: inline-block;
                        padding-bottom: 5px;
                    }

                    &.ask-offer button {
                        border-radius: 3px;
                    }
                }
            }
        }

        #not-enough-stock-confirmation {
            table.product-list {

                // Odd rows
                tr:nth-child(2n) {
                    td {
                        border-color:transparent white;
                    }
                }

                tr:last-child td {
                    border-bottom:4px solid @blue;
                }

                th {
                    background-color:@blue;
                    color:white;
                    font-weight:700;
                    font-size:0.9em;
                    vertical-align: middle;

                    span {
                        font-size:0.7em;

                        ._tablet({
                            display:block;
                            text-align: center;

                        });
                    }

                    &.total {
                        ._uppercase;
                    }

                    ._tablet({
                        &.cc-txt-right {
                            text-align:center;
                        }
                    });
                }

                td {
                    font-size:0.78em;
                    font-weight:700;
                    border-width:1px;
                    border-style: solid;
                    border-color:transparent #f2faff;
                    ._transition;

                    &.product {

                        // Images
                        &-images {

                            .product-images-name {
                                display: flex;
                                flex-wrap: wrap;
                                min-width: 350px;
                            }


                            .first {
                                flex: 0 0 150px;
                                max-width: 150px;

                                img {
                                    display:block;
                                    margin: 0 auto;

                                    & + img {
                                        margin-top: 5px;
                                    }
                                }
                            }

                            .second {
                                flex: 1;
                                align-self : center;
                            }
                        }

                        // Référence
                        &-reference {
                            span {
                                white-space: nowrap;
                            }
                        }

                        // Status
                        &-status {
                            text-align:center;

                            span.available {
                                ._inline;
                                height:18px;
                                width:18px;
                                ._border-radius(50%);
                                vertical-align: middle;
                            }
                        }

                        // Unit price
                        &-unit-price {
                            span {
                                color:@blue;
                                font-size:1.05em;
                                font-weight:700;
                            }
                        }

                        // Qtty commandée
                        &-quantity {
                            input {
                                ._inline;
                                padding:0;
                                text-align:center;
                                height:35px;
                                max-width:90px;

                                ._tablet({
                                    font-size:1em;

                                });
                            }
                        }

                        // Quantité réelle livrée
                        &-quantity-delivery {

                        }

                        // Total price
                        &-total-price {
                            min-width:95px;

                            span {
                                font-size:1.2em;
                                font-weight:700;

                                // Tablet
                                ._tablet({
                                    font-size:1em;
                                });
                            }
                        }

                        //delete
                        &-delete {
                            a {
                                display:block;
                                margin:0 auto;
                                height:40px;
                                width:40px;
                                line-height:40px;
                                text-align:center;
                                color:@red;
                                font-size:1.4em;
                                ._border-radius(100%);
                                ._transition;

                                &:hover {
                                    color:white;
                                    background-color:@red;
                                }

                                i:before {
                                    ._reset;
                                }
                            }
                        }
                    }
                }

                ._tablet({
                    display:block;
                    overflow-x:auto;

                });
            }
        }
    }

    ._tablet({
        section {

            // 4. Confirmation
            &#order-confirm {

                // Main columns container
                .final-steps {
                    .column {
                        margin-top: 30px;
                    }
                }
            }
        }
    });

    // Phone rules
    ._phone({
        section {

            h2 {
                font-size:1.1em;
            }

            // 1. Recap panier
            &#cart-summary {

                table.product-list {

                    thead {
                        display:none;
                    }

                    tbody {
                        display:block;
                        width:100%;

                        tr:last-child td {
                            border:0;
                        }

                        tr {
                            ._flex-display;
                            ._flex-direction(column);
                            width:100%;
                            margin:0;
                            padding:15px 0;
                            border-bottom:2px dashed #a8a8a8;
                            background-color:#fafafa;

                            // Magic with CSSUA.js library
                            .ua-safari &,
                            .ua-mobile-iphone & {
                                display:block;
                            }


                            td {
                                ._reset;
                                padding:5px 0;
                                background-color:white;
                                font-weight:400;
                                ._flex(0 0 100%);

                                // Magic with CSSUA.js library
                                .ua-safari &,
                                .ua-mobile-iphone & {
                                    display:block;
                                    min-height:32px;
                                    ._clearfix;
                                }

                                &.product {
                                    // Img + titles
                                    &-images {
                                        padding:10px 0 5px;
                                        text-align:center;
                                        font-size:1.15em;
                                        font-weight:700;

                                        .first {
                                            display:none;
                                        }

                                        .product-images-name {
                                            min-width: 100%
                                        }
                                    }

                                    // Status
                                    &-status {
                                        span.available {
                                            width:50.000%;
                                            height:inherit;
                                            ._border-radius(0px);
                                            vertical-align: top;

                                            em {
                                                color:white;
                                                font-style:normal;
                                            }
                                        }
                                    }


                                    &-quantity-delivery,
                                    &-total-price,
                                    &-quantity,
                                    &-unit-price {
                                        text-align:center;

                                        .cc-s-only {
                                            padding-right:10px;
                                        }
                                    }

                                    &-total-price {
                                        font-size:1.1em;
                                    }
                                }
                            }
                        }
                    }


                }

                form .form-actions {
                    text-align:center;
                }

                .order-summary {

                    .action-btns {
                        text-align:center;

                        a {
                            margin:0 auto 20px;
                            display:block;

                            span {
                                ._inline;
                            }

                            i:before {
                                margin-right:10px;
                            }
                        }
                    }

                    .columns.column .cc-12-s {
                        text-align:center;

                        p {
                            display:block;
                            margin:0 auto;
                        }
                    }
                }

                // Phrase : "Hors TVA , frais de transport et autres frais si applicables"
                .warning {
                    text-align : center
                }
            }

            // 2. Ajouter un commentaire
            // 3. Ajouter des destinataires
            &#order-comment, &#order-reference,
            &#order-delivery {
                input {
                    max-width:100%;
                }
            }

            // 4. Confirmation
            &#order-confirm {
                button {
                    height:inherit;
                    padding:7px 20px;
                    font-size:1em;
                    display:block;
                    width:100%;

                    &:before {
                        display:none;
                    }
                }
            }
        }

    });
}
