/* ********* */
/** COOKIES **/
/* ********* */

/* ********************* */

/*
    - Automatically add a header banner to the page
    - More infos : http://ec.europa.eu/ipg/basics/legal/cookies/index_en.htm
*/

#cookies {
    display:none;
    ._clearfix;
    position:fixed;
    bottom:0px;
    left:0px;
    z-index:99999;
    width:100%;
    background-color:#ffffff;
    ._box-shadow('0px 0px 5px 0px rgba(0,0,0,0.5)');
    padding:7px 20px;

    i {
        margin-right:10px;
    }

    a.close {
        float:right;
        ._opacity(1);
        font-weight:400;
        text-shadow:none;
    }
}
