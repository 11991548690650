/*
    == HELPERS ==
*/
.clearfix {._clearfix;}

.cc {

    // Texts
    &-txt {
        &-left {text-align:left;}
        &-center {text-align:center;}
        &-right {text-align:right;}
        &-justify {text-align:justify;}

        // /!\ colors and bg-colors are generated in generators.less file
        // /!\ paddings and margins are generated in generators.less file
    }

    // Paddings
    &-pa {
        padding:@helper-pa;
        &t {padding-top:@helper-pat;}
        &r {padding-right:@helper-par;}
        &b {padding-bottom:@helper-pab;}
        &l {padding-left:@helper-pal;}
    }

    // Margins
    &-ma {
        margin:@helper-ma;
        &t {margin-top:@helper-mat;}
        &r {margin-right:@helper-mar;}
        &b {margin-bottom:@helper-mab;}
        &l {margin-left:@helper-mal;}
    }



    // Visibility
    &-hidden {
        display:none !important;

        &-s {
            ._phone({
                display:none !important;
            });
        }
        &-t {
            ._tablet({
                display:none !important;
            });
        }
        &-d {
            display:none !important;

            ._tablet({
                display:block !important;
            });
        }
    }
    &-s-only {
        ._tabletUp({
            display:none !important;
        });
    }
    &-block {display:block}
    &-inline {._inline;}
    &-inside{._inside;}
    &-displayTable{._displayTable;}
    &-centered {
        display:block;
        margin-left:auto;
        margin-right:auto;
    }

    /* Width */
    // All width are generated in generator.less file
    // Specific width
    &-w-quarter {
        width:25.00%;
    }
    &-w-third {
        width:33.333%;
    }
    &-w-half {
        width:50.00%
    }
    &-w-twothird {
        width:66.666%;
    }
    &-w-threequarter {
        width:75.00%;
    }

    /* Clearfix */
    &-clearfix {._clearfix}
}
