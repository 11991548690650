// Ezicut Eshop
.wrap-ezicut {

    // COMMON CSS
    h2 {
        ._inline;
        color:@blue;
        text-transform:none;
        margin-bottom:0;
        font-size:2.3rem;
        position:relative;
        top:3px;
        margin-right:15px;

        .help {
            ._inline;
            position:relative;
            color:@blue;
            font-size:1.3em;
            top:3px;
            margin-left:5px;
        }
    }

    // Iphone only, text help for select2 just above product & matter select
    .ip-only {
        display:none;

        .ua-mobile-iphone & {
            padding:10px;
            display:block;
            margin-top:0;
        }
    }

    select {
        ._inline;
        padding:0 0 0 1em;
        background-color:@input-bg;
        height: @input-lh;
        line-height:1;
        border:1px solid @input-bc;
        vertical-align: middle;
        font-size:@input-fs;
        width:350px;
        max-width:100%;
        ._box-shadow('inset 0px 1px 2px 0px rgba(0,0,0,0.1)');
        ._border-radius(unit(@input-br,px));
        ._transition;

        /* Hack IE8 & < */
        height:inherit\9;
        padding:0.8em 0.3em 0.8em 1em\9;

        &:focus {
            outline:none;
            border-color:@form-mc;
        }
    }

    .get-values {
        p {
            margin-bottom:5px;

            span {
                ._inline;
                background-color:white;
                text-align:center;
                width:90px;
                margin-left:20px;
                color:@blue;
                font-weight:700;
            }

            &.warn {
                margin-bottom:0;

                span {
                    ._reset;
                    display:block;
                    width:100%;
                    font-size:0.8em;
                    color:@text-mc;
                    font-weight:400;
                    padding:15px;
                }
            }
        }
    }



    // ARTICLE
    .wrap-article {
        text-align:center;
        border-bottom:3px dashed #a9a9a9;
        padding-bottom:15px;
        span.select2 {
            width: 350px !important;
            text-align: left;

            span.select2-selection{
                display:block;
                padding:0 0 0 1em;
                height: @input-lh;
                border:1px solid @input-bc;
                vertical-align: middle;
                font-size:@input-fs;
                width: 100%;
                max-width:100%;
                margin-bottom:0;
                ._box-shadow('inset 0px 1px 2px 0px rgba(0,0,0,0.1)');
                ._border-radius(unit(@input-br,px));
                ._transition;

                /* Hack IE8 & < */
                height:inherit\9;
                padding:0.8em 0.3em 0.8em 1em\9;

                &:focus {
                    outline:none;
                    border-color:@form-mc;
                }

                span.select2-selection__rendered {
                    line-height:54px;
                    padding: 0;
                }
                span.select2-selection__arrow {
                    height:47px;
                    b {
                        border-color:#131313 transparent transparent;
                        border-width:6px 3px 0;
                    }
                }
            }
        }

        .result {
            background-color:#f4f4f4;
            padding:15px;
            display:none;
            margin-top:15px;

            // Show loading
            &.load {
                display:block;

                .loading {
                    display:block;
                }
            }

            // Show results
            &.shown {
                display:block;

                .article-operation,
                .article-img,
                .article-caracteristics,
                .article-name {
                    ._transition;
                    ._opacity(1);
                    height:auto;
                }

                .loading {
                    display:none;
                }
            }

            .article-name {
                font-weight:700;
                padding-bottom:5px;
                ._opacity(0);
                height:0;
            }

            .article-operation {
                ._opacity(0);
                height:0;
                font-size:2rem;
                padding-bottom:5px;
            }

            .article-img {
                ._opacity(0);
                height:0;
                padding:10px 0 15px;

                img {
                    display: inline-block;
                    vertical-align: middle;
                    margin:0 10px;
                }
            }

            .article-caracteristics {
                ._opacity(0);
                height:0;
            }
        }
    }


    // GROUPE MATIERE
    .wrap-groupe-matiere {
        border-bottom:3px dashed #a9a9a9;
        padding:15px 0;
        text-align:center;

        .get-values {
            input {
                width:90px;
            }
        }

        .select-group {
            position:relative;
            ._inline;
        }

        .select-groupe-matiere {
            display:none;
            &.shown {
                ._inline;
            }
        }

        // Matière help
        .matiere-help {
            ._inline;
            vertical-align: middle;
            margin-left:10px;

            select {
                color:@text-mc;
                vertical-align: top;
                width:170px;
            }
        }

        .contain-help {
            padding-top:15px;
        }

        .column {
            padding:10px 20px;
            background-color:#f4f4f4;

            &.columns {
                padding:10px;
            }

            .get-img {
                text-align:left;
            }

            select {
                color:@blue;
                font-weight:700;
                margin-bottom:0;
            }

            .column {
                ._reset;
            }

            &.no-compatibility {
                display: none;
                background-color: white;
                margin-bottom: 20px;
                padding: 0;

                .alert {
                    margin:10px 0;
                }
            }

            // Grade
            &.matiere-grade {
                @red : #a4523c;
                @green : #5ea652;

                background-color:white;
                margin-bottom:15px;
                padding:0 20px;
                color:@red;
                display:none;

                // Show loading
                &.load {
                    display:block;

                    .loading {
                        display:block;
                    }
                }

                // Show results
                &.shown {
                    display:block;

                    p, .grade {
                        ._transition;
                        ._opacity(1);
                        height:auto;
                    }
                    .grade {
                        height:50px;
                    }

                    .loading {
                        display:none;
                    }
                }

                // Grade 1 : orange
                &.grade-1 {
                    color:@orange;

                    .grade {
                        background-color:@orange;
                    }
                }

                // Grade 2 et 3 : green
                &.grade-3,
                &.grade-2 {
                    color:@green;

                    .grade {
                        background-color:@green;
                    }
                }

                p {
                    vertical-align: middle;
                }

                .grade {
                    background-color:@red;
                    color:white;
                    ._border-radius(100%);
                    width:50px;
                    height:50px;
                    text-align:center;

                    span {
                        ._inline;
                        font-size:3rem;
                    }

                    em {
                        font-size:1.9rem;
                        font-style:normal;
                    }
                }

                p,.grade {
                    ._inline;
                    font-weight:700;
                    margin:0 10px;
                    ._opacity(0);
                    height:0;
                }
            }
        }
    }

    // RESET BTN
    button, a.btn {
        margin:15px 5px 0;
    }

    ._tablet({
        // Groupe matière
        .wrap-groupe-matiere {
            .column {
                margin-bottom:30px;
            }
        }
    });


    ._phone({


        // COMMON CSS
        h2 {
            display:block;
            margin-bottom:10px;
            position:static;
            margin-right:0;
        }



        select {
            width:100%;
        }

        .get-values {
            p {
                text-align:center;

                span {
                    margin:0 auto;
                    display:block;
                }
            }
        }

        .wrap-article {
            span.select2 {
                width: 100% !important;
            }
        }

        // Groupe matière
        .wrap-groupe-matiere {
            .select-group {
                display:block;

                .help {
                    top:-35px;
                    right:10px;
                }
            }

            // Matière help
            .matiere-help.shown {
                select {
                   display:block;
                   width:100%;
                   margin:0;
                }
            }



            .column {
                .get-img.cc-12-s {
                    text-align:center;
                    padding-bottom:10px;
                }
            }
        }

        button {
            font-size:1em;
        }

    });
}
