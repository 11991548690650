/*
    == DESKTOP / GENERAL CSS ==
*/
html {
    font-size:62.5%;
    font-size:~'calc(1em*0.625)';
    overflow-x: hidden;
}

body {
    ._reset;
    ._helvetica;
    font-size: @text-fs-old;
    font-size: @text-fs;
    line-height: @text-lh;
    color: @text-mc;
    overflow-x: hidden;
    ._box-sizing(border-box);

    /* Box-sizing */
    *,
    *:before,
    *:after {
        ._box-sizing(border-box);
    }

    // Affix - Override Bootstrap CSS
    &.affix { position:relative; }
}
div,dl,dd,dt,p,ul,li,ol {
    ._reset;
    list-style:none;
}

/* define text selection */
::-moz-selection {
    background: @text-mc;
    text-shadow: none;
    color:white;
}
::selection {
    background: @text-mc;
    text-shadow: none;
    color:white;
}
