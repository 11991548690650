/*
    == ANIMATIONS ==
*/

/*
** Animations list **
    ._loading-spin
*/

/* *****
    @name : ._loading-spin
    @description : animate loading buttons
***** */
._loading-spin(@duration:1s){
  animation-name: loading-spin;
  animation-duration: @duration;
  animation-timing-function: linear;
  transform-origin: 50% 50%;
  animation-iteration-count: infinite;
}
@keyframes loading-spin {
  from {._rotate(0deg);}
  to{._rotate(360deg);}
}
@-webkit-keyframes loading-spin {
  from {._rotate(0deg);}
  to{._rotate(360deg);}
}
