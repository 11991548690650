/* ******* */
/** FORMS **/
/* ******* */

/* ********************* */


form {
    /* Reset margin, padding, border */
    ._reset;

    .form {

        /*
            Parent element : containe label + input, textarea, select, etc...

            <div class="form-item">
                <label>...</label>
                <input ... />
            </div>
        */
        &-item {
            ._clearfix;
            margin:0.8em 0;

            /* Display as inline-block */
            &.inline {
                ._inline;
                margin-left:1em;
                vertical-align: middle;

                &:nth-child(1) {
                    margin-left:0;
                }
            }


            /* Chained inputs */
            &.chained {
                font-size:0; // Collpase inputs */
                @border-radius : unit(@input-br,px); /* Border-radius calculation */

                label {
                    font-size:@text-fs;
                }

                input {
                    /* Middles inputs */
                    ._border-radius(0px);

                    /* First input */
                    &:first-of-type {
                        ._border-radius(@border-radius 0px 0px @border-radius);
                    }

                    /* Last input */
                    &:last-child{
                        ._border-radius(0px @border-radius @border-radius 0px);
                    }
                }

                /* Submit button */
                button[type="submit"] {
                    ._border-radius(0px @border-radius @border-radius 0px);
                }

                /* Input with prefix/suffix */
                .form-ps {
                    ._inline;
                    margin-right:5px;

                    & + input[type="submit"],
                    & + button[type="submit"] {
                        ._border-radius(@border-radius);
                    }
                }
            }
        }


        /*
            Prefix / Suffix

            <div class="form-item">
                <div class="form-ps">
                    <span>...</span>
                    <input ... />
                    <span>...</span>
                </div>
            </div>
        */
        &-ps {
            font-size:0; /* Collpase span with input */
            @border-radius : unit(@input-br,px); /* Border-radius calculation */
            ._inline;
            vertical-align: middle;

            :first-child {
                ._border-radius(@border-radius 0px 0px @border-radius);
            }
            :last-child {
                ._border-radius(0px @border-radius @border-radius 0px);
            }
            input:not(:first-child):not(:last-child) {
                ._border-radius(0px);
            }

            span {
                ._inline;
                vertical-align: middle;
                height: @input-lh;
                line-height:@input-lh;
                background-color:@prefix-bg;
                padding:@prefix-pa;
                color:@prefix-tc;
                font-size:@input-fs;

                /* Specific for Suffix */
                &:last-child {
                    background-color:@suffix-bg;
                    padding:@suffix-pa;
                    color:@suffix-tc;
                }
            }
        }


        /*
            - Radio && Checkbox inputs

            <div class="form-item">
                <div class="form-radio">
                    <label>
                        <input type="radio" name="gender" value="1" />
                        Mister
                    </label>
                </div>
                <div class="form-radio">
                    <label>
                        <input type="radio" name="gender" value="2" checked="checked" />
                        Madam
                    </label>
                </div>
            </div>
        */
        &-checkbox,
        &-radio {
            ._inline;
            position:relative;
            margin-right:1em;

            &.block {
                display:block;
                margin:0;
            }

            /* Disabled radio or checkbox inputs */
            &.disabled {
                label {
                    cursor:not-allowed; /* old browser */
                    pointer-events:none;
                    ._opacity(0.5);

                    input {
                        ._opacity(0.8);
                    }
                }
            }

            label {
                cursor:pointer;
                position:relative;

                &.checked.error,
                &.error,
                &.checked.is-invalid,
                &.is-invalid {
                    color:@red-da;

                    &:before {
                        color:@red-da;
                    }
                }

                &::-moz-selection {
                    background: transparent;
                    color:inherit;
                }
                &::selection {
                    background: transparent;
                    color:inherit;
                }
            }

            /* Change viewport appearance */
            input {
                appearance:none;
                border:1px solid @input-bc;
                background-color:white;
                width:14px;
                height:14px;
                margin:0 5px 0 0;
                position:relative;
                top:1px;
                vertical-align: baseline;

                &:focus {
                    outline:none;
                    border-color:@input-bc;
                }

                &:before {
                    content:' ';
                    position:absolute;
                    top:50%;
                    left:50%;
                    margin:-3px 0 0 -3px;

                }
            }
        }

        /*
            - Specific for checkbox inputs
        */
        &-checkbox {
            input {
                &:before {
                    display:none;
                    width:6px;
                    height:4px;
                    border-left:1px solid white;
                    border-bottom:1px solid white;
                }

                &:checked {
                    background-color:@form-mc;
                    border-color:@form-mc;
                    &:before {
                        display:block;
                        ._rotate(-45deg);
                    }
                }
            }
        }

        /*
            - Specific for radio inputs
        */
        &-radio {
            input {
                ._border-radius(100%);


                &:before {
                    display:block;
                    width:6px;
                    height:6px;
                    ._border-radius(100%);
                    background-color:transparent;
                }

                &:checked {
                    &:before {
                        background-color:@form-mc;
                    }
                }
            }
        }
    }


    /*
        - Label
    */
    label {
        ._reset;
        ._inline;
        vertical-align: middle;
        padding-right:0.8em;
        font-weight:400;

        &.block {
            display:block;
            line-height:2em;
            padding-right:0;
        }

        &.error,
        &.is-invalid {
            color:@red;
        }
    }


    /*
        - input
        - textarea
    */
    textarea,
    input {
        ._inline;
        ._box-shadow('inset 0px 1px 2px 0px rgba(0,0,0,0.1)');
        ._border-radius(unit(@input-br,px));
        vertical-align: middle;
        background-color:@input-bg;
        padding:0 1em;
        border:1px solid @input-bc;
        resize:none;
        height: @input-lh;
        font-size:@input-fs;
        ._transition;

        /* Hack IE8 and < */
        @line-height-IE : @input-lh;
        line-height:~"@{line-height-IE}\9"; /* hot fix IE 8 */


        &:focus {
            outline:none;
            border-color:@form-mc;
        }

        /* Error inputs */
        &.error,
        &.is-invalid {
            ._box-shadow('none');
            background-color:lighten(@red,50%);
            border-color:lighten(@red,40%);
            color:@red-da;

            &:focus {
                outline:none;
                border-color:lighten(@red,10%);
            }

            /* placeholder color */
            &::-moz-placeholder {color:lighten(@red-li,15%);}
            &::-ms-input-placeholder {color:lighten(@red-li,15%);}
            &::-webkit-input-placeholder {color:lighten(@red-li,15%);}
        }

        /* number : fix right padding */
        &[type="number"] {
            padding-right:0;
            min-width:60px;
        }

        /* disabled element */
        &[disabled] {
            cursor:not-allowed; /* old browser */
            pointer-events:none;
            ._opacity(0.5);
        }

        /* Reset type file input */
        &[type="file"] {
            ._reset;
            height:inherit;
            ._box-shadow('none');
        }

        /* Placeholder color */
        &::-moz-placeholder {color:@gray;}
        &::-ms-input-placeholder {color:@gray;}
        &::-webkit-input-placeholder {color:@gray;}
    }

    /*
        - Specific for textareas
    */
    textarea {
        padding:0.8em 1em;
        line-height:1.5;
        min-height:110px;
        vertical-align: top;
        width:100%;
    }

    /*
        - select
    */
    select {
        padding:0 0 0 1em;
        background-color:@input-bg;
        height: @input-lh;
        line-height:1;
        border:1px solid @input-bc;
        vertical-align: middle;
        font-size:@input-fs;
        ._box-shadow('inset 0px 1px 2px 0px rgba(0,0,0,0.1)');
        ._border-radius(unit(@input-br,px));
        ._transition;

        /* Hack IE8 & < */
        height:inherit\9;
        padding:0.8em 0.3em 0.8em 1em\9;

        &:focus {
            outline:none;
            border-color:@form-mc;
        }
    }

    /*
        - Submit btns
    */
    input[type="submit"],
    button[type="submit"] {
        cursor:pointer;
        color:white;
        border:0;
        height: @input-lh;
        padding:0 2em;
        vertical-align: middle;
        ._transition;
        ._uppercase;
        ._box-shadow('none');
        ._border-radius(unit(@input-br,px));
        ._btn(@submit-bg);

        &[class*="fa-"],
        &[class*="ion-"] {
            padding-left:1.5em;

            &:before {
                margin-right:1em;
            }
        }
    }
}
