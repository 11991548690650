// Animate modal product detail
._animLoader() {
    -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
    animation: sk-foldCubeAngle 2.4s infinite linear both;

    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    ._rotate(-180deg,'X', 140px);
    opacity: 0;
  } 25%, 75% {
    ._rotate(0deg,'X', 140px);
    opacity: 1;
  } 90%, 100% {
    ._rotate(180deg,'Y', 140px);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    ._rotate(-180deg,'X', 140px);
    opacity: 0;
  } 25%, 75% {
    ._rotate(0deg,'X', 140px);
    opacity: 1;
  } 90%, 100% {
    ._rotate(180deg,'Y', 140px);
    opacity: 0;
  }
}
