/* LISTING VIDEOS
*/

.videos {

    // Videos items
    .video-item {
        text-align:center;
        padding:40px 0;

        // Title
        h2 {
            ._reset;
            font-size:1.26em;
            line-height:1.3em;
            color:@blue;
            font-weight:400;
            padding:20px 0;
            overflow: hidden;
        }

        // Text
        p {
            font-size:1.6rem;
            color:black;
            font-weight: 400;
            line-height:1.2;
            text-align: justify;
        }
    }
}
