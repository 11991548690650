/*
  * Extend the ChuckCSS settings.less file *
  *** OVERRIDE CHUCKCSS VARS ***
  * Specific vars
*/
._helvetica() {
    font-family: Helvetica, "Helvetica Neue", Arial, sans-serif;
}
._montserrat() {
    font-family: 'Montserrat', sans-serif;
}
/*
    -- @ Media Queries @ --
*/
@tablet-mw : 1200px;
/*
    -- @ Colors @ --
*/
/* Primary color */
@primary : #0062a6;
@blue : @primary;
@blue-da : #042147;
@red : #c41515;
@redswiss : #ed1c24;
@green : #80ae37;
@orange: #f39c12;
@black : #1a1a1a;

@aColors :
    green @green,
    blue @primary,
    blueda @blue-da,
    red @red,
    orange @orange,
    black @black;

// Couleurs des famille de produits
@micro : #cc7400;
@fraisage: #003c65;
@percage : #67836c;
@outils : #9d9d9d;
@ebauches : #5c1d1b;
@pieces : @outils;

/*
    -- @ Texts @ --
*/
@text-mc: #333333;
@text-fs-old : 19px;

/*
    -- @ Links @ --
*/
@links-btn-br : 0;
@links-btn-lh : 1.5;
/*
    -- @ Titles @ --
*/
@title-mc : @blue-da;
@title-h1-fs : 1.8em;
@title-h2-fs : 1.26em;
@title-h3-fs : 1.1em;

/*
    -- @ Tables @ --
*/
@tables-striped-odd : #f2faff;  /* Odd lines bg-color */

/*
    -- @ Forms @ --
*/
@form-mc : @primary;
@input-lh : @text-fs*2.8;
@input-bc : #ddd;
@input-br : 3;
@submit-bg : @primary;
/*
    -- @ Grids @ --
*/
@cols-gutter : 2em;
@cols-total : 12;
@cols-pa : 0;
@cols-masonry-gap : 1.5em;




@xs-media-mw : 359px;     /* Max-width for xs screens */
@s-media-mw : 767px;      /* Max-width for small devices */
@m-media-mw : 989px;      /* Max-width for medium devices */
@l-media-mw : 1199px;     /* Max-width for large devices */
/*
    * X-Small media
*/
// screen <= 359px | equivalent to ._tiny({}) mixin in ChuckCSS < v3.0.0
._media-xs(@rules) {
    @media screen and (max-width: (@xs-media-mw)) {
        @rules();
    }
}
// screen > 359px | equivalent to .phoneUp({}) mixin  in ChuckCSS < v3.0.0
._media-xs-up(@rules) {
    @media screen and (min-width: (@xs-media-mw + 1)) {
        @rules();
    }
}
// 0px <= screens <= 359px
._media-xs-only(@rules) {
    @media screen and (max-width: @xs-media-mw) and (min-width: 0px){
        @rules();
    }
}

/*
    * Small media
*/
// screen <= 767px | equivalent to ._phone({}) mixin in ChuckCSS < v3.0.0
._media-s(@rules) {
    @media screen and (max-width: @s-media-mw) {
        @rules();
    }
}
// screen > 767px | equivalent to ._tabletUp({}) mixin in ChuckCSS < v3.0.0
._media-s-up(@rules) {
    @media screen and (min-width: (@s-media-mw + 1)) {
        @rules();
    }
}
// 360px <= screen <= 767px
._media-s-only(@rules) {
    @media screen and (max-width: @s-media-mw) and (min-width: (@xs-media-mw + 1)) {
        @rules();
    }
}

/*
    * Medium media
*/
// screen <= 989px
._media-m(@rules) {
    @media screen and (max-width: @m-media-mw) {
        @rules();
    }
}
// screen > 989px
._media-m-up(@rules) {
    @media screen and (min-width: (@m-media-mw + 1)) {
        @rules();
    }
}
// 768px <= screen <= 990px
._media-m-only(@rules) {
    @media screen and (max-width: @m-media-mw) and (min-width: (@s-media-mw + 1)) {
        @rules();
    }
}

/*
    * Large media
*/
// screen <= 1199px | equivalent to ._tablet({}) mixin in ChuckCSS < v3.0.0
._media-l(@rules) {
    @media screen and (max-width: @l-media-mw) {
        @rules();
    }
}
// screen > 1199px | equivalent to ._desktop({}) mixin in ChuckCSS < v3.0.0
._media-l-up(@rules) {
    @media screen and (min-width: (@l-media-mw + 1)) {
        @rules();
    }
}
// 990px <= screen <= 1199px
._media-l-only(@rules) {
    @media screen and (max-width: @l-media-mw) and (min-width: (@m-media-mw + 1)) {
        @rules();
    }
}

// For IE only -- Yo_oY
._ie(@rules) {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @rules();
    }
}
