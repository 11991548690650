/* ******** */
/** GRIDS **/
/* ******** */

/* ********************* */

/*
    - General Grids
*/

._generateColumns; // Launch _generateColumns
._tablet({._generateTabletColumns;}); // Launch _generateTabletColumns
._phone({._generatePhoneColumns;}); // Launch _generatePhoneColumns

.columns {
    ._flex-display;
    ._flex-wrap(wrap);
    margin:0 -@cols-gutter/2;

    &.cc {
        &-no-multiligne {._flex-wrap(nowrap);}
        &-reverse{._flex-direction(row-reverse);}
        &-justify {
            &-starting      {._flex-justify(flex-start);}
            &-ending        {._flex-justify(flex-end);}
            &-centering     {._flex-justify(center);}
            &-spacing       {._flex-justify(space-between);}
            &-equal-spacing {._flex-justify(space-around);}
        }
        &-no-gutter {
            margin:0;
            .column {
                margin:@cols-ma-top 0 @cols-ma-bottom;
            }
        }
        &-gutter {
            .column {
                margin:@cols-ma-top @cols-gutter/2 @cols-ma-bottom;
            }
        }
        &-animated {
            .column {
                ._transition;
            }
        }
        &-masonry {
            display:block;
            margin:0;
            column-gap: @cols-masonry-gap;

            &.cc-no-gutter .column,
            .column {
                ._inline;
                width:100%;
                max-width:100%;
                ._flex(none);
                margin:@cols-masonry-gap/2 0;
            }
        }
        &-spaceless {
            &>.column {
                margin-top:0;
                margin-bottom:0;
            }
        }
    }



    .column {
        ._reset;
        padding:@cols-pa;
        margin:@cols-ma-top @cols-gutter/2 @cols-ma-bottom;
        ._flex(1);

        /* Nested columns */
        &.columns {
            ._flex(1);
            padding:@cols-gutter @cols-gutter/2;

            &.cc-no-gutter {
                padding:0;
            }
        }

        &.cc {
            &-first {._flex-order(-1);}
            &-last {._flex-order(1);}
            &-right {margin-left:auto;}
            &-left {margin-right:auto;}
            &-center {
                margin-left:auto;
                margin-right:auto;
            }
            &-align {
                &-top {
                    ._flex-display;
                    ._flex-align-items(flex-start);
                }
                &-center {
                    ._flex-display;
                    ._flex-align-items(center);
                }
                &-bottom {
                    ._flex-display;
                    ._flex-align-items(flex-end);
                }

                &-self {
                    &-top {._flex-align-self(flex-start);}
                    &-bottom {._flex-align-self(flex-end);}
                    &-center {._flex-align-self(center);}
                    &-stretch {._flex-align-self(stretch);}
                }
            }
            &-w-auto {
                ._flex(0 0 auto);
            }
        }
    }


    /* TABLET rules */
    ._tablet({
        &.cc-gutter .column.cc,
        .column.cc {
            &-offset-0-t,
            &-no-offset-t {
                margin-left: @cols-gutter/2;
            }

            &-first-t {
                ._flex-order(-2);
            }

            &-last-t {
                ._flex-order(2);
            }
        }

        &.cc-no-gutter .column.cc-offset-0-t,
        &.cc-no-gutter .column.cc-no-offset-t {
            margin-left:0;
        }
    });


    /* PHONE rules */
    ._phone({

        &.cc-gutter .column.cc,
        .column.cc {
            &-offset-0-s,
            &-no-offset-s {
                margin-left: @cols-gutter/2;
            }

            &-first-s {
                ._flex-order(-3);
            }

            &-last-s {
                ._flex-order(3);
            }
        }

        &.cc-no-gutter .column.cc-offset-0-s,
        &.cc-no-gutter .column.cc-no-offset-s {
            margin-left:0;
        }
    });
}

