/* CMS PAGES :
    * CMS
*/

.cms {
  // CMS Content page
  #cms-content {
    padding-bottom: 50px;

    // AREA tabs
    .tabs {
      margin-bottom: 50px;
      line-height: 2em;

      li {
        &:before {
          display: none;
        }

        a {
          background-color: #f0f0f0;
          border: 0;
          color: @blue;
          padding: 0 30px;
          ._transition;

          &:hover {
            color: white;
            background-color: @blue;
          }
        }
      }
    }
  }

  // Specific for news detail page
  &.news-detail {

    .news-date {
      text-align: center;
      color: @blue;
      font-size: 1.15em;
      font-weight: 400;
      font-style: italic;
      margin-bottom: 40px;
    }

    // Left column
    .cc-4 {
      padding-right: 40px;

      a {
        display: block;
        margin-bottom: 20px;
        position: relative;

        &.youtube {
          margin-bottom: 40px;
        }

        &.fa-linkedin-square {
          color: white;
          border-color: #0077b5;
          ._btn(#0077b5);

        }

        img {
          vertical-align: bottom;
          position: relative;
          z-index: 11;

          &.mask-video {
            z-index: 12;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }

    // Detail news tablet rules
    ._tablet({
      .cc-4 {
        a {
          font-size: 1em;
        }
      }

    });

    .area {
      margin-bottom: 40px;

      &.area-0 {
        margin-top: 40px;
      }
    }
  }
}
