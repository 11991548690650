// Eshop : product detail page
#product-detail,
.eshop.product-detail {

    h1 {
        text-align:left;
        text-transform:none;
        font-size:1.57em;
        margin-bottom:20px;
    }

    // LEFT COLUMN
    .product-description {
        margin-bottom:20px;
    }

    .product-availability {
        padding-bottom: 0;
        span {
            ._inline;
            height:18px;
            width:18px;
            ._border-radius(50%);
            vertical-align: middle;
            margin-left:20px;

            &.value {
                vertical-align: inherit;
                margin-left: 0px;
            }

            &.available {
                background-color:#62a471;
            }
            &.unavailable {
                background-color:#ab5e5e;
            }
            &.fast-available {
                background-color:#0062a6;
            }
            &.fa-stack {
                vertical-align: baseline;
            }
        }
    }

    .fa-cloud-download {
        ._border-radius(3px);
    }

    .product-images {

        p {
            padding-bottom:30px;

            .legend {
                color:#9c9c9c;
                font-size:0.73em;
            }
        }
    }

    // RIGHT COLUMN
    .product-caracteristics {
        td {
            border:0;

            i:before {
                margin-right:0;
            }
        }
    }

    .add-to-cart {
        border-top:3px dashed #a8a8a8;
        margin-top:40px;
        padding-top:40px;

        form {
            .form-item {
                text-align:center;
                ._uppercase;
                font-size:0.94em;

                label {
                    font-weight: 700;
                    text-align:right;
                    width:150px;
                    padding-right:40px;
                }

                .product-price {
                    ._inline;
                    vertical-align: middle;
                    font-weight: 700;
                    color:@blue;
                    font-size:1.21em;
                    width:150px;
                    text-align:left;
                }

                input {
                    width:150px;
                }

                button {
                    margin-top:20px;
                    font-weight: 700;
                    padding:0 50px;
                }
            }
        }
    }

    ._tablet({

        .product-availability {
            padding-bottom:20px;
            text-align:center;
        }

        .fa-cloud-download {
            display:block;
        }
    });

    // Phone rules
    ._phone({

        .add-to-cart {
            margin-top:20px;
            padding-top:20px;

            form .form-item {
                label,
                .product-price {
                    ._reset;
                    display:block;
                    text-align:center;
                    width:100%;
                }
                input {
                    width:100%;
                    text-align:center;
                }

                button {
                    padding:0 20px;
                    font-size: 1em;
                    display: block;
                    width: 100%;
                }
            }
        }

    });

}
