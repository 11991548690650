// HEADER
#main-header {
  background-color:white;
  position: relative;
  z-index:2;

  // Common
  ul {
    ._reset;

    li {
      list-style: none;
    }
  }

  i:before {
    ._reset;
  }

  // Top level : Logo + Tools + Langs + Social
  .wrap-header-top {
    .cc-inside {
      width: 100%;
      position: relative;

      .open-menu {
        position: absolute;
        z-index:2;
        top:6px;
        left:0px;

        button:hover {
          background-color:transparent
        }
      }

      .header-langs-social {
        background-color:@blue-da;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding:10px;
        padding-left:80px;

        .langs {
          display: flex;

          a {
            display: block;
            margin-right:20px;
            opacity:0.5;
            ._transition;

            img {
              display: block;
            }

            &:hover,
            &.active {
              opacity: 1;
            }
          }
        }

        .social {
          a {
            display: inline-block;
            width:30px;
            margin-left:20px;
            color:white;
            font-size: 3rem;
            height: 20px;
          }
        }
      }

      #logo {
        display: block;
        text-align: center;
        margin:20px 0 0;
      }

      .header-tools {
        display: flex;
        margin:20px 0;

        li {
          // flex:1;
          width:25.00%;

          .ua-ie & {
            max-width:180px;
          }

          .link-like, // Specific for catalogs
          & > a {
            display: block;
            text-align: center;

            &.eshop {
              em {
                color:@redswiss;
              }

              span {
                background-color:@redswiss;
              }
            }

            img {
              display: block;
              max-height:35px;
              margin: 0 auto;
              width: auto;
            }

            span {
              display: flex;
              align-items: center;
              justify-content: center;
              margin:0 auto;
              background-color: @blue;
              font-size: 2rem;
              color: white;
              padding:10px;
              border-radius:100%;
              width: 35px;
              height: 35px;

              i {
                display: inherit;
              }
            }

            em {
                display: block;
                padding-top:5px;
              color:@blue;
              font-style: normal;
              font-size: 1.8rem;
              line-height: 1.2;
            }
          }

          // Specific for catalogs
          &.header-tools-catalog {
            .wrap-catalog-actions {
                padding-top:5px;
                display: flex;
                flex-wrap:wrap;
                justify-content: center;
                align-content: center;

                a {
                    flex:0 0 80%;

                    .ua-ie & {
                        width: 80%;
                        max-width: 80%;
                        min-width: 80%;
                    }

                    &:nth-child(1) {
                        margin-bottom:5px;
                    }
                }
            }
          }

          // Téléchargez les raccourcis Ezi Direct & Ezi Cut
          &.long-label {
            em {
              font-size:1.7rem;
            }
          }
        }
      }
    }
  }

  // Main menu
  .wrap-main-menu {
    position: fixed;
    top: 0px;
    left: -110%;
    width:100vw;
    z-index: -1;
    height: 100vh;
    overflow-y: auto;
    background-color: white;
    ._transition;
    transition-timing-function: cubic-bezier(1, 0, 0, 1);

    &.shown {
      z-index: 19;
      left: 0%;
    }

    .cc-inside {
      position: relative;
    }

    .open-menu {
      position: absolute;
      right:0;
      top:6px;

      button {
        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
          background-color:@blue-da;
        }
      }
    }

    nav {
      padding:60px 0 20px;

      // First level <ul>
      ul.menu-eshop,
      ul.main-site-menu {
        text-align: center;
        color: @blue-da;

        & > li {
          padding:7px 0 30px;

          &.eshop-only {
            display: none;
          }

          &.user,
          &.logout,
          &.cart {
            a {
              i {
                margin-right:10px;
                font-size: 1.4rem;
                position: relative;
                //top:-2px;
              }
            }
            .cart-preview {
              display: none;
              position: absolute;
              top: 100%;
              right: 0;
              width: 350px;
              text-align: left;
              padding: 5px 0;
              color: #333;
              background-color: #0062a6;
              .product-list {
                margin: 0;
                th,
                td {
                  font-size: .84em;
                }
                th {
                  color: #fff;
                  font-weight: 700;
                }
                .product-delete a i {
                  font-size: 1.2em;
                }
              }
              .total {
                color: #fff;
                float: right;
                margin: 5px 40px 0px 0px;
                font-weight: 700;
              }
            }
          }

          & > a {
            ._montserrat;
            text-transform: uppercase;
            font-weight: 700;
            padding:10px 0;
            display: flex;
            justify-content:center;
            align-items: center;

            &.show-main-menu {
              i {
                margin-right:10px;
                font-size: 1.4rem;

                &.fa-bars {
                  position: relative;
                  //top:-2px;
                }
              }
            }

            &.active,
            &:hover {
              background-color:@blue;
              color:white;
            }

            // Chevron
            i.fa-chevron-down {
              margin-left:10px;
              position: relative;
              top:-4px;
            }
          }

          // Sub-menus
          ul {
            padding-top:5px;

            a {
              display: block;
              padding:4px 0;

              &.active {
                color:@blue;
              }
            }
          }
        }
      }

      // Specific for main site menu
      ul.main-site-menu {
        // Social Networks links
        & > div {
          &.social {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 3rem;
            padding: 18px 20px;
            & > a {
              color: white;
              &:hover {
                color: @blue;
              }
              &:not(:last-child) {
                margin-right: 5px;
              }
            }
          }
        }
      }

      // Specific for menu eshop
      ul.menu-eshop {
        display: none;
      }

      // Eshop pages : show eshop menu
      body.eshop & {
        ul.main-site-menu {
          display: none;

          & > li.eshop-only {
            display: block;
          }
        }

        ul.menu-eshop {
          display: block;
        }

        ul.main-site-menu,
        ul.menu-eshop {
          &.shown {
            display: block;
          }

          &.hidden {
            display: none;
          }
        }
      }
    }
  }



  // Screen > 767px
  ._media-s-up({

    .wrap-header-top {
      .cc-inside {
        display: flex;
        flex-wrap:wrap;
        align-items: center;

        #logo {
          flex:0 0 264px;
          max-width:264px; // Hack IE :/
          ._reset;
          padding-left:20px;
        }

        .header-langs-social {
          flex:0 0 100%;
          max-width: 100%; // Hack IE :/
        }

        .header-tools {
          ._reset;
          padding-right:20px;
          flex:1;
          justify-content:flex-end;

          .ua-ie & {
            flex:1 0 auto;
          }

          li {
            // flex:1;
            max-width:180px;
            padding-top:20px;

            .ua-ie & {
              // flex:0 0 180px;
            }

            .link-like, // Specific for catalogs
            a:not(.cc-thin) {
              padding : 20px 15px;

              img {
                max-height:50px;
                margin: 0 auto 15px;
              }

              span {
                font-size: 2.8rem;
                width: 50px;
                height: 50px;
                margin:0 auto 15px;
              }
            }

            // Specific for catalogs
            &.header-tools-catalog {
                .link-like {
                    position:relative;

                    &:hover {
                        img,
                        em {
                            ._transition;
                            opacity: 0.3;
                        }

                        .wrap-catalog-actions {
                            ._transition;
                            opacity: 1;
                        }
                    }

                    .wrap-catalog-actions {
                        padding-top:0px;
                        opacity: 0;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index:2;

                        a {
                            text-transform: uppercase
                        }
                    }
                }
            }
          }
        }
      }
    }

    // Main menu
    .wrap-main-menu {
      &.shown {
        .sticky-logo,
        .langs {
          display: none;
        }
      }
    }
  });


  // Screen > 1199px
  ._media-l-up({
    .wrap-header-top {
      .cc-inside {
        width:1200px;

        .open-menu {
          display: none;
        }

        #logo {
          padding:0;
          order:-2;
        }

        .header-tools {
          order:-1;
          padding-right: 0;

          li {
            min-width: 180px;

            .link-like, // Specific for catalogs
            a:not(.cc-thin) {
              padding-bottom :13px;

              &:hover {
                span {
                  top:-5px;
                }
              }

              span {
                position: relative;
                top:0;
                ._transition;
              }
            }
          }
        }

        .header-langs-social {
          order:0;
          flex:inherit;
          background-color:transparent;
          display: none;
          padding:0px;
          padding-left:20px;

          .langs {
            a {
              margin-right:0px;
              margin-left:10px;
            }
          }

          .social {
            text-align: right;

            a {
              display: inline-block;
              margin-left:10px;
              margin-top:10px;
              color:@blue;
              height: inherit;
              ._transition;

              &:hover {
                color:@blue-da;
              }
            }
          }
        }
      }
    }

    // Main menu
    .wrap-main-menu {
      position: static;
      display: block;
      background-color:@blue-da;
      z-index: 1;
      height: inherit;
      overflow-y: inherit;
      transition: none;

      &.shown {
        .sticky-logo,
        .langs {
          display: inherit;
        }
      }

      .open-menu {
        display: none;
      }

      nav {
        padding:0;

        // First level <ul>
        ul.menu-eshop,
        ul.main-site-menu {
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: stretch;

          & > li {
            padding:0;
            position: relative;

            &:hover {

              & > a {
                background-color:@blue;
              }
              & > ul {
                display: block;
              }
            }

            &.sticky-logo {
              & > a {
                background-color:inherit;
              }
            }

            &.cart:hover .cart-preview {
              display: block;
            }

            & > a {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 18px 20px;
              font-size: 1.6rem;
              color: white;
              font-weight: 400;
              background-color: @blue-da;
              height: 100%;
              ._transition;

              &.show-main-menu {
                i.fa-bars {

                }
              }

            }

            // Second level <ul>
            & > ul {
              display: none;
              position: absolute;
              top:100%;
              left:0;
              width:350px;
              text-align: left;
              padding:5px 0;
              background-color:@blue;

              li {
                a {
                  color:white;
                  text-transform: none;
                  font-size: 1.8rem;
                  line-height: 1.2;
                  font-weight: 400;
                  padding:5px 30px;
                  background-color:@blue;
                  ._transition;

                  &.active,
                  &:hover {
                    color:white;
                    background-color:@blue-da;
                  }
                }
              }
            }
            &.langs {
              & > ul {
                width: auto;
              }
              img {
                max-width: none;
              }
            }
          }
        }

        // Specific for menu eshop
        ul.menu-eshop {
          display: none;
        }

        // Eshop pages : show eshop menu
        body.eshop & {
          ul.main-site-menu {
            display: none;

            & > li.eshop-only {
              display: block;
              margin-right: auto;
            }
          }

          ul.menu-eshop {
            display: flex;

            & > li.first {
              margin-right:auto;
            }
          }

          ul.main-site-menu,
          ul.menu-eshop {
            &.shown {
              display: flex;
            }

            &.hidden {
              display: none;
            }
          }
        }
      }
    }
  });

}

// FOOTER
#main-footer {
    margin-top: auto;
    position: relative;

    .eshop & {
        margin-top:30px;
    }

  // TOOLS
  .footer-tools {
    padding:30px 0;

    i:before {
      ._reset;
    }

    .wrap-tools {
      ._reset;
      display: flex;
      flex-wrap: wrap;

      li {
        list-style: none;
        width:25.00%;

        .link-like, // Specific for catalogs
        a:not(.cc-thin) {
          display: block;
          text-align: center;

          img {
            display: block;
            margin: 0 auto;
            max-height:35px;
            width: auto;
          }

          span {
            display: flex;
            align-items: center;
            justify-content: center;
            margin:0 auto;
            background-color: white;
            font-size: 2rem;
            padding:10px;
            color:white;
            border-radius:100%;
            width: 35px;
            height: 35px;

            i {
              color:@blue;
              display: inherit;
            }
          }

          em {
            color:white;
            padding-top:5px;
            display: block;
            font-style: normal;
            font-size: 1.8rem;
            line-height: 1.2;
          }
        }

        // Specific for catalogs
        &.header-tools-catalog {
            .wrap-catalog-actions {
                padding-top:5px;
                display: flex;
                flex-wrap:wrap;
                justify-content: center;
                align-content: center;

                a {
                    flex:0 0 80%;

                    .ua-ie & {
                        width: 80%;
                        max-width: 80%;
                        min-width: 80%;
                    }

                    &:nth-child(1) {
                        margin-bottom:5px;
                    }
                }
            }
        }
      }
    }
  }

  // REINSURANCES FOOTER
  .reinsurances {
    background-color:#f4f4f4;
    padding:30px 0;
    border-bottom:16px solid @blue-da;

    .wrap-reinsurances {
      display: flex;
      flex-wrap: wrap;

      .item {
        flex:0 0 50.00%;
        padding:20px 10px;
        text-align: center;
        max-width:50.0%; // Hack IE :/

        .icon {
          width: 60px;
          height:60px;
          margin:0 auto 20px;
          background-color: white;
          border-radius: 100%;
          box-shadow:1px 1px 3px 0px rgba(0,0,0,0.75);
          display: flex;
          justify-content:center;
          align-items: center;

          span {
            width: 52px;
            height:52px;
            border:2px solid @blue;
            border-radius: 100%;
            display: flex;
            justify-content:center;
            align-items: center;

            i {
              color:@blue;
              font-size:2.5rem;

              &:before {
                display: block;
                ._reset;
              }
            }
          }
        }

        &.reinsurance-2 {
          .icon {
            span {
              i {
                font-size: 4.5rem;
              }
            }
          }
        }

        // title
        h3 {
          color:@blue;
          text-transform: uppercase;
          font-size: 1.6rem;
          font-weight: 700;
          margin-bottom:15px;
        }

        // text
        p:not(.icon) {
          font-size: 1.6rem;
          color:#000;
          font-weight: 400;
        }
      }
    }
  }

  // GOOGLE MAPS + CONTACT
  .footer-maps {
    width: 100%;
    z-index: 1;

    .contact {
      padding:30px 20px;
      text-align:center;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color:rgba(255,255,255,0.9);

      img {
        ._inline;
        vertical-align: bottom;
        margin-bottom:20px;
      }

      h3 {
        ._reset;
        font-size:1.26em;
        color:#222222;
        margin-bottom:9px;
        font-weight:400;
      }

      p {
        font-weight: 400;
        color:black;
        margin-bottom:9px;
      }

      ul {
        margin:1em 0 0;
        display: flex;
        flex-wrap: wrap;

        &:before,
        &:after {
          content: '';
          display: none;
        }

        li {
          flex:0 0 50%;
          font-size:0.74em;
          margin-bottom:15px;
          max-width:50.0%; // Hack IE :/

          strong {
            font-weight: 400;
            font-size: 1.7rem;
          }
        }
      }
    }
  }

  // MENU
  .footer-menu {
    background-color:@blue-da;
    padding:50px 20px 0px;

    .cc-inside > p {
      text-align: center;
      padding-bottom:30px;

      img {
        max-width:100px;
      }
    }

    // Common
    ul {
      ._reset;

      li {
        list-style: none;
      }
    }

    ul.main-menu-footer {
      display: flex;
      flex-wrap: wrap;

      & > li {
        flex:0 0 100%;
        max-width:100%; // Hack IE :/
        //order:-6;
        padding:10px 0;
        border-top:1px solid rgba(255,255,255,0.5);

        &:nth-child(1) {
          border-top-width:0;
        }

        &.branch {
          order:-6;
        }

        /*
        // Savoir-faire
        & + li {
          order:-5;

          // Gammes
          & + li {
            order : -4;

            // Téléchargement
            & + li {
              order:-2;

              // Vidéos
              & + li {
                order: -1;

                // Eshop
                & + li {
                  order:-3;
                }
              }
            }
          }
        }
        */

        & > a {
          display: flex;
          width: 100%;
          color:white;
          font-size: 1.6rem;
          text-transform: uppercase;

          i {
            margin-left: auto;

            &:before {
              ._reset;
              display: block;
            }
          }
        }

        // Sub-menu
        ul {
          display: none;

          &.shown {
            display: block;
          }

          li {
            a {
              display: block;
              padding:2px 0;
              color:white;
              font-size: 1.4rem;
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  // CREDITS
  .footer-credits {
    background-color:@blue-da;
    padding:20px 20px 15px;

    p {
      color:white;
      font-size: 1.2rem;
      text-align: center;

      a {
        color:white;
      }
    }
  }

  // Screen > 767px
  ._media-s-up({

    // TOOLS
    .footer-tools {
      padding: 20px 0;

      .wrap-tools {

        li {
          // flex:1;
          max-width:180px;

          .ua-ie & {
            // flex:0 0 180px;
          }

          .link-like, // Specific for catalogs
          a:not(.cc-thin) {
            padding : 30px 15px;

            img {
              max-height:50px;
              margin: 0 auto 15px;
            }

            span {
              font-size: 2.8rem;
              width: 50px;
              height: 50px;
              margin:0 auto 15px;
            }
          }

            // Specific for catalogs
            &.header-tools-catalog {
                .link-like {
                    position:relative;

                    &:hover {
                        img,
                        em {
                            opacity: 0.3;
                            ._transition;
                        }

                        .wrap-catalog-actions {
                            opacity: 1;
                            ._transition;
                        }
                    }

                    .wrap-catalog-actions {
                        padding-top:0px;
                        opacity: 0;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index:2;

                        a {
                            text-transform: uppercase
                        }
                    }
                }
            }
        }
      }
    }

    // MAPS
    .footer-maps {
      display: block;
      height:450px;
      position: relative;
      background: transparent url('/img/bg-footer.jpg') no-repeat center center;
      background-size:cover;

      .contact {
        position:absolute;
        z-index:8;
        top:0px;
        left:0%;
        width:400px;
        height: 100%;
        padding:30px 40px;
      }
    }

    // MENU
    .footer-menu {
      ul.main-menu-footer {
        display: flex;
        flex-wrap: wrap;

        & > li {
          flex:0 0 33.2%;
          max-width:33.2%; // Hack IE :/
          padding:20px;
          border-top-width:0px;
          text-align: center;

          & > a {
            display: inline-block;
            width: inherit;

            i {
              display: none;
            }
          }

          // Sub-menu
          ul {
            display: block !important;
            padding-top: 15px;

            li {
              a {
                display: inline-block;
              }
            }
          }
        }
      }
    }

  });

  // Screen > 1999px;
  ._media-l-up({

    // TOOLS
    .footer-tools {

      .wrap-tools {

        li {
          flex:0 0 25.00%;
          max-width:25.00%; // Hack IE :/


          a:not(.cc-thin) {
            &:hover {
              span {
                top:-5px;
              }
            }

            span {
              position: relative;
              top:0;
              ._transition;
            }
          }
        }
      }
    }

    // REINSURANCES
    .reinsurances {
      padding:20px 0;

      .wrap-reinsurances {
        .item {
          flex:0 0 25.00%;
          max-width:25.00%; // Hack IE :/
          padding:30px;

          .icon {
            width: 100px;
            height:100px;

            span {
              width: 86px;
              height:86px;
              border:4px solid @blue;

              i {
                font-size:3.6rem;
              }
            }
          }

          &.reinsurance-2 {
            .icon {
              span {
                i {
                  font-size: 6.2rem;
                }
              }
            }
          }
        }
      }
    }

    // MENU
    .footer-menu {
      .cc-inside {
        display: flex;

        & > p {
          flex:0 0 150px;
          text-align: left;
          padding-bottom:0px;
          padding-right:30px;

          img {
            max-width:inherit;
          }
        }
      }
    }
  });
}
