
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: Helvetica, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 12px;
  opacity: 0;
  filter: alpha(opacity=0);
}
.tooltip.in {
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.tooltip.top {
  margin-top: -3px;
  padding: 5px 0;
}
.tooltip.right {
  margin-left: 3px;
  padding: 0 5px;
}
.tooltip.bottom {
  margin-top: 3px;
  padding: 5px 0;
}
.tooltip.left {
  margin-left: -3px;
  padding: 0 5px;
}
.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #ffffff;
  text-align: center;
  background-color: #000000;
  border-radius: 4px;
}
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000000;
}
.tooltip.top-left .tooltip-arrow {
  bottom: 0;
  right: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000000;
}
.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  left: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000000;
}
.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000000;
}
.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000000;
}
.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000000;
}
.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  right: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000000;
}
.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000000;
}

/*
    - Simply override default bootstrap tooltip stylesheet
*/
.setTooltipBgColor(@color) {
    // Wrapper for the tooltip content
    .tooltip-inner {
        background-color: @color;
    }
    &.top,
    &.top-left,
    &.top-right {
        .tooltip-arrow {
            border-top-color: @color;
        }
    }
    &.right .tooltip-arrow {
        border-right-color: @color;
    }
    &.left .tooltip-arrow {
        border-left-color: @color;
    }
    &.bottom-right,
    &.bottom-left,
    &.bottom {
        .tooltip-arrow {
            border-bottom-color: @color;
        }
    }
}
.tooltip {

    /* 'error' class is used in .error inputs to show ERRORS (red bg color) tooltips ! */
    &.error {
        .setTooltipBgColor(@red);

        background-color:transparent; /* Drupal */
        background-image:none; /* Drupal */
    }

    /* 'success' class is used to show SUCCESS for exemple (green bg color) tooltips ! */
    &.success {
        .setTooltipBgColor(@green);

        background-color:transparent; /* Drupal */
        background-image:none; /* Drupal */
    }

    ul {
        li {
            color:white;
            list-style:none;
        }
    }
}
