/* ******** */
/** ALERTS **/
/* ******** */

/* ********************* */

/*
    - General alerts styles
    - types : warning, error, success, info

    <div class="alert alert-{type}">
        ...
    </div>
*/

.alert {
    padding: 1em;
    padding-right:45px;
    border: 1px solid transparent;
    margin:1.5em 0;
    position:relative;
    ._border-radius(unit(@alert-br,px));

    /* Links inside a .alert element */
    a {
        text-decoration:underline;
    }

    /* List : add margin */
    ul {
        width:90%;
        margin:1em auto;
    }

    /* Closed icon */
    button.close {
        ._reset;
        display:block;
        position:absolute;
        right:0px;
        top:0px;
        padding:1em;
        height:100%;
        background-color:transparent;
        ._opacity(1);

        i {
            ._reset;

            &:before {
                ._reset;
                font-size:@text-fs*1.5;
            }
        }
    }

    /* Alerts types */
    &-primary {
        background-color: lighten(@primary,43%);
        border-color: lighten(@primary,38%);
        color:@primary;

        p,div,ul,
        button.close,
        a {
            color:@primary;
        }
    }
    &-info {
        background-color: @alert-in-bg;
        border-color: @alert-in-bc;
        color:@alert-in-tc;

        p,div,ul,
        button.close,
        a {
            color:@alert-in-tc;
        }
    }
    &-warning {
        background-color: @alert-wa-bg;
        border-color: @alert-wa-bc;
        color:@alert-wa-tc;

        p,div,ul,
        button.close,
        a {
            color:@alert-wa-tc;
        }
    }
    &-error {
        background-color: @alert-er-bg;
        border-color: @alert-er-bc;
        color:@alert-er-tc;

        p,div,ul,
        button.close,
        a {
            color:@alert-er-tc;
        }
    }
    &-success {
        background-color: @alert-su-bg;
        border-color: @alert-su-bc;
        color:@alert-su-tc;

        p,div,ul,
        button.close,
        a {
            color:@alert-su-tc;
        }
    }
}
