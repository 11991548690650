/* ******** */
/** MODALS **/
/* ******** */

/* ********************* */

/*
    - General Modals


     <div id="modal-id" class="modal">
            <button class="modal-close"></button>

            <div class="modal-overlay"></div>

            <div class="modal-content">
                <div class="modal-header">
                    ...
                </div>
                <div class="modal-body">
                    ...
                </div>
                <div class="modal-footer">
                    ...
                </div>
            </div>
        </div>
*/

body.opened-modal {
    overflow:hidden !important;
}

/* Modal wrapper */
.modal {
    ._flex-display;
    ._flex-justify(center);
    ._flex-align-items(center);
    ._box-shadow('none');
    z-index:-1;
    position:fixed;
    top:0px;
    bottom:0px;
    left:0px;
    right:0px;
    ._opacity(0);

    /* Shown modal */
    &.active {
        ._opacity(1);
        z-index:99;

        .modal-content {
            ._opacity(1);
            ._scale(1);
            top:0px;
        }

        .modal-close {
            ._transition(0.3s, top, ease-out, 0.3s);
            top:20px;

            // Set top to 8px on phone devices
            ._phone({
                top:8px;
            });
        }

        .modal-overlay {
            background-color:rgba(0,0,0,0.85);
        }
    }


    /* Sizes */
    &.modal {
        &-large {
            .modal-content {
                width:80%;
            }
        }
        &-small {
            .modal-content {
                width:30%
            }
        }

        // Set width 90% on phone devices.
        ._phone({
            .modal-content {
                width:90% !important;
            }
        });
    }

    /* Close button */
    &-close {
        ._reset;
        position:fixed;
        top:-60px;
        left:50%;
        z-index:98;
        background:none;
        ._border-radius(100%);
        line-height:inherit;
        background-color:transparent;
        width:40px;
        height:40px;
        margin-left:-20px;


        &:after,
        &:before {
            ._inline;
            content:' ';
            width:19px;
            height:3px;
            background-color:white;

            ._border-radius(4px);
            position:absolute;
            top:19px;
            left:10px;
        }
        &:before {
            ._rotate(-45deg);
        }
        &:after {
            ._rotate(45deg);
        }

        &:hover {
            background-color:lighten(@red,25%);
        }
    }

    /* Content */
    &-content {
        width:50%;
        max-height:80vh;
        overflow-y:auto;
        overflow-x:hidden;
        background-color:white;
        z-index:99;
        ._border-radius(4px);
        ._opacity(0);
        ._transition;
        ._scale(0.5);

        .modal-body,
        .modal-footer,
        .modal-header {
            padding:20px;
        }

        .modal-header {
            border-bottom:1px solid #cecece;
            color:@gray;
            ._uppercase;
        }

        .modal-footer {
            border-top:1px solid #cecece;
        }
    }

    /* Overlay */
    &-overlay {
        position:fixed;
        background-color:transparent;
        top:0px;
        left:0px;
        right:0px;
        bottom:0px;
        z-index:97;
    }
}
