/*
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
    padding: 10px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, -webkit-filter;
    transition-property: opacity, filter;
    transition-property: opacity, filter, -webkit-filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
}
.hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
}
.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: white;
    border-radius: 4px;
    position: absolute;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
    content: "";
    display: block;
}
.hamburger-inner::before {
    top: -10px;
}
.hamburger-inner::after {
    bottom: -10px;
}








/*
 * Elastic
 */
.hamburger--elastic .hamburger-inner {
    top: 2px;
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.15s 0.4s ease;
}
.hamburger--elastic .hamburger-inner::after {
    top: 20px;
    transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic.is-active .hamburger-inner {
    -webkit-transform: translate3d(0, 10px, 0) rotate(135deg);
    transform: translate3d(0, 10px, 0) rotate(135deg);
    transition-delay: 0.1s;
}
.hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0;
}
.hamburger--elastic.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -20px, 0) rotate(-270deg);
    transform: translate3d(0, -20px, 0) rotate(-270deg);
    transition-delay: 0.1s;
}
