/*
    GAMME
*/

.gamme {
    h1 {
        &:after {
            content: "";
            display: none;
        }

        span {
            display: block;
            width: 75px;
            height: 4px;
            margin: 15px auto 40px;
        }
    }

    .css-ask-quotation {
        padding-top: 50px;

        a {
            padding: 0 30px;
            line-height: 5.2rem;
            text-transform: uppercase;
        }
    }

    // ZONE IMAGE + BODY
    .wrap-gamme-slider-body {
        padding: 50px 0;

        // Slider
        .gamme-slider {
            max-width: 600px;
            margin: 0 auto;

            .main-carousel {
                width: 100%;

                .carousel-cell {
                    width: 100%;
                    position: relative;

                    img {
                        position: relative;
                        z-index: 2;
                        display: block;
                    }
                }
            }
        }

        // Body
        .gamme-body {
            padding-top: 20px;
            color: white;
            font-weight: 400;
            font-size: 1.8rem;

            strong {
                font-weight: 400;
            }
            a {
                color: white;
                display: inline;
                text-decoration: underline;
            }
        }
    }

    // ZONE ACCORDEONS && BLOC AUTO-PROMOTION
    .wrap-accordeons-bloc {
        padding: 25px 0 50px;

        .gamme-zones {
            padding-bottom: 50px;

            .gamme-zone {
                border-bottom: 2px dashed #e9e9e9;
                padding: 25px 0;

                &.shown {
                    h2 a i {
                        ._rotate(-180deg);
                    }

                    .gamme-zone-text {
                        display: block;
                    }
                }

                h2 {
                    a {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        color: #333333;
                        font-size: 2rem;

                        i {
                            display: block;
                            margin-left: 20px;
                            ._transition;
                            ._rotate(0deg);

                            &:before {
                                ._reset;
                                display: block;
                            }
                        }
                    }
                }

                .gamme-zone-text {
                    padding: 30px 0 0;
                    font-weight: 400;
                    color: black;
                    font-size: 1.8rem;
                    display: none;

                    strong {
                        font-weight: 400;
                    }

                    .wrap-table {
                        max-width: 100%;
                        overflow-x: auto;
                    }

                    td {
                        white-space: nowrap;

                        // Link "Voir dans le Eshop"
                        a.btn {
                            ._helvetica;
                            line-height: 1.2;
                        }

                        &:nth-child(2) {
                            min-width: 50px;
                            width: 50px;

                            img {
                                max-height: 20px;
                                max-width: inherit;
                                vertical-align: middle;
                                width: auto;
                            }

                            a {
                                display: flex;
                                padding: 3px 0px;
                                align-items: center;
                            }
                        }
                    }
                }
            }
        }

        .wrap-autopromotions {
            .autopromotion {
                a {
                    display: block;
                }

                img {
                    display: block;
                    margin: 0 auto 30px;
                }
            }
        }
    }

    // Screen > 989px;
    ._media-m-up({
        .wrap-gamme-slider-body {
            & > .cc-inside {
                display: flex;
            }

            .gamme-slider {
                flex:0 0 400px;
            }

            .gamme-body {
                padding-top: 0px;
                padding-left: 40px;
                flex: 1;
            }
        }
    });

    // Screen > 1999px
    ._media-l-up({

        .wrap-gamme-slider-body {
            .gamme-slider {
                flex:0 0 600px;
            }
        }

        // ZONE ACCORDEONS && BLOC AUTO-PROMOTION
        .wrap-accordeons-bloc {

            & > .cc-inside {

                &.contain-accordeons {
                    display: flex;

                    .gamme-zones {
                        flex: 1;
                        padding-bottom:0;
                    }

                    .wrap-autopromotions {
                        padding-top:25px;
                        flex:0 0 450px;
                        padding-left:40px;
                    }
                }
            }
        }
    });
}

