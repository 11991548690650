// Homepage Eshop
.eshop {
    // Common to all Select2
    .select2-results__group {
        cursor: pointer;

        &:hover {
            background: rgb(91, 153, 248);
            color: #fff;
        }
    }

    // STRUCTURE PAGE
    .wrap-search-modules {
        display: flex;

        // Search by reference produits
        /*
        .form-ref-products {
            padding-top:10px;
            flex:0 0 300px;
            margin-left:30px;
            border-left:1px solid #a8a8a8;
            padding-left:30px;
        }
        */

        // Search by type usinage, groupe matuère, selects, Actions btns
        .form-operation-matter {
            flex:1;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-top:10px;

            .type-usinage,
            .groupe-matiere,
            .products-reference {
                flex:0 0 31%;
            }

            .products-reference .form-item {
              margin: 0;
            }

            .sub-filters {
                flex:1 0 100%;

            }

            .form-actions {
                flex:1 0 100%;
                margin: 15px 0px 0px;
            }
        }

        /*
        .form-ref-products {
          flex:1;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding-top:10px;
          flex: 0 0 30%;
          .products-reference{
            flex: 0 0 47%;
          }
          .catalog-tools {
            flex: 0 0 47%;
            .wrap-catalog-actions {
              a {
                margin-bottom: 3px !important;
              }
              a:not(:first-child) {
                margin-left: 3px !important;
              }
            }
          }
        }
        */
    }


    // SEARCH FORM
    #form-search {
        outline:3px dashed #a8a8a8;
        outline-width:3px 0;
        padding:25px 0;
        position:relative;
        ._cover;

        // Accroissement de l'ombre portée dans les select
        select {
            ._box-shadow('inset 0px 1px 2px 0px rgba(0,0,0,0.5)');
            display:block;
            width:100%;

            &:disabled {
                background-color: #E3E3E3;
            }
        }

        h2 {
            margin-bottom: 10px;
        }


        // Pay with PAYPAL
        .cc-inside {
            position:relative;

            .contain-paypal-push {
                position: absolute;
                top:-75px;
                right: 0;
                background-color:@blue-da;
                padding:0px 10px;

                a {
                    color:white;
                    display: flex;
                    align-items: center;
                    font-size: 1.4rem;
                    text-transform: none;

                    em {
                        font-style: normal;
                    }

                    img {
                        margin-left:5px;
                        max-height:18px;
                    }

                }
            }
        }

        label {
            ._reset;
            display:block;
            text-align:center;
            color:@blue;
            font-weight:700;

            .help {
                ._inline;
                margin-left:10px;
                color:@blue;

                i {
                    ._reset;
                }
            }
        }

        .form-ref-products {
          /*
            label {
                margin-bottom: 24px;
            } */

            .form-item {
              margin-top: 0px;
            }

            .form-actions {
                a {
                    margin-left:0px;
                }
            }
        }

        .description {
            text-align:center;
            font-size:0.84em;
            padding:10px 0;
            height:58px;
            vertical-align: middle;

            .help {
                ._inline;
                margin-left:10px;
                color:@blue;
                font-size:1.2em;
                vertical-align: middle;

                i {
                    ._reset;
                }
            }
        }

        // Select 2
        span.select2 {
            width:100% !important;

            span.select2-selection{
                display:block;
                padding:0 0 0 1em;
                height: @input-lh;
                border:1px solid @input-bc;
                vertical-align: middle;
                font-size:@input-fs;
                width: 100%;
                max-width:100%;
                margin-bottom:0;
                ._box-shadow('inset 0px 1px 2px 0px rgba(0,0,0,0.5)');
                ._border-radius(unit(@input-br,px));
                ._transition;

                /* Hack IE8 & < */
                height:inherit\9;
                padding:0.8em 0.3em 0.8em 1em\9;

                &:focus {
                    outline:none;
                    border-color:@form-mc;
                }

                span.select2-selection__rendered {
                    line-height:54px;
                    padding: 0;
                }

                span.select2-selection__arrow {
                    height:47px;
                    b {
                        border-color:#131313 transparent transparent;
                        border-width:6px 3px 0;
                    }
                }

                span.select2-selection__clear {
                    padding:2px;
                    font-weight:0;
                    font-size:25px;
                    left:-28px;
                    top:-6px;
                }
            }
        }

        // Search matter : help box
        .matiere-help {
            padding-top:10px;
            text-align:center;
            display:none;

            &.shown {
                display:block;
            }

            p {
                color:@blue;
                font-weight:700;
            }

            span {
                ._inline;
                width:170px;
                background-color:white;
                border:1px solid #dddddd;
                ._border-radius(3px);
                height:@input-lh;
                line-height:@input-lh;
                text-align:center;
                vertical-align: top;
                color:@blue;
                font-weight:700;
                margin:0 5px;
            }

            select {
                ._inline;
                color:@text-mc;
                vertical-align: top;
                width:170px;
                margin:0 5px;
            }
        }

        // Iphone only, text help for select2 on references
        .ip-only {
            display:none;

            .ua-mobile-iphone & {
                display:block;
                margin-top:0;
                padding:10px;
            }
        }


        // Sub Filters after 3 main
        .sub-filters {
            padding-top:15px;
            text-align:center;
            display:flex;
            flex-wrap:wrap;
            justify-content:center;
            align-items:flex-end;

            img.pp-img {
                ._opacity(0);
                width:0px !important;
                height:0px !important;
            }

            .form-item {
                .fa-stack {
                    vertical-align: sub;
                }

                text-align:left;
                background-color:white;
                border-bottom:5px solid @blue;
                padding:10px;
                //._inline;
                display: inline-flex;
                align-items: baseline;
                margin:0 10px 10px;

                .form-checkbox {
                    margin-right: 0;
                }

                .form-check {
                    margin-right: 0;
                    display: inline-flex;
                    align-items:baseline;
                    //display: inline;
                    zoom: 1;
                    position: relative;
                }

                &.highlighted {
                    background-color: #D4EDFC;
                }

                &.mal0 {
                    margin-left: 0;
                }
                &.mar0 {
                    margin-right: 0;
                }

                .popover-img {
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 10px;

                    &.mar {
                        margin-right: 30px;
                    }
                }


                // Specific for substratum labels
                &.form-substratum {
                    [for*="substratum_0"] {
                        margin-right:20px;
                    }
                }

                // Specific for Attachments
                .form-attachement > div {
                    ._inline;
                }

                // Common to all sub-filters
                select {
                    height:35px;
                    width:60px;
                    padding:0;
                    display: inline-block;
                    vertical-align: middle;
                    margin-left:10px;

                    &[disabled="disabled"] {
                        background-color: #E3E3E3;
                        // These following lines disable the arrow
                        ::-ms-expand { display: none; }
                        -moz-appearance: none;
                        -webkit-appearance:none;
                    }
                }

                label {
                    overflow:hidden;
                    color:black;
                    font-weight:700;
                    display: inline-block;
                    vertical-align: middle;
                    padding-right: 0px;

                    img {
                        ._inline;
                        vertical-align: middle;
                        margin-right:30px;
                    }
                }

                input {
                    margin-left:0px;

                    &:disabled {
                        background-color: #E3E3E3;
                    }
                }

                i.fa-info-circle {
                    color:@blue;
                    /* font-size:1.1em; */
                    /* vertical-align: middle; */

                    &.mal {
                        margin-left:10px;
                    }

                    &:before {
                        ._reset;
                    }
                }
            }
        }

        .form-actions {
            text-align:center;
            display:block;

            input {
                font-weight:700;
                ._border-radius(0px);
            }

            a {
                ._reset;
                padding:0 10px;
                margin-left:40px;
                line-height:40px;
                font-size:0.84em;

                i {
                    font-size:0.8em;
                }
            }
        }
    }

    // NO PRODUCTS ALERT
    .no-product {
        font-size:0.92em;

        i {
            font-size:1.7em;
            margin-left:20px;
            margin-right:20px;
            vertical-align: middle;
        }
    }

    // TABLE RESULTS
    #product-lists {
        padding:25px 0 0px;



        h2 {
            small {
                ._inline;
                color:@blue;
                text-transform:none;
                font-size:1em;
                font-weight:700;
            }
            &.simple {
              margin-bottom: 20px;
            }
        }

        table {
            .fa-stack {
                vertical-align: text-bottom;
            }

            // Odd rows
            tr:nth-child(2n) {
                td {
                    border-color:transparent white;
                }
            }

            th {
                background-color:@blue;
                color:white;
                font-weight:700;
                font-size:1.05em;
                vertical-align: middle;

                &.active {
                    background-color:@blue-da;
                }

                a {
                    color:white;
                    font-weight:700;
                    display:block;

                    i {
                        display:none;
                    }

                    &:after {
                        ._inline;
                        ._fa('\f0dc',0.84em,white);
                        margin-left:5px;
                    }

                    &.sorted-asc:after {
                        ._fa('\f0d8',0.84em,white);
                    }

                    &.sorted-desc:after {
                        ._fa('\f0d7',0.84em,white);
                    }
                }

                span {
                    font-size:0.7em
                }
            }

            td {
                padding:8px 4px;
                font-size:0.84em;
                font-weight:700;
                border-width:1px;
                border-style: solid;
                border-color:transparent #f2faff;
                ._transition;

                &.minW50 {
                    min-width:50px;
                }

                &.minW200 {
                    min-width:200px;
                }

                &.minW100 {
                    min-width:100px;
                }

                &.width330 {
                    min-width:330px;
                    width:330px;
                }

                &.width115 {
                    min-width:115px;
                    max-width:115px;
                    width:115px;
                }

                &.maxW350 {
                    max-width:350px;
                }

                // Images
                &.images {
                    img {
                        ._inline;
                        vertical-align: middle;
                        margin-left:10px;

                        &:nth-child(1) {
                            margin-left:0;
                        }
                    }
                }

                &.title {
                    a {
                        display:block;

                        i:before {
                            color:@blue;
                            font-size:1.2em;
                        }
                    }

                    .ua-ie & {
                        max-width:inherit;
                        width:inherit;
                    }
                }

                // Price
                &.price {
                    color:@blue;
                    font-size:1.05em;
                }

                // Conditions de coupe
                &.cond-coupe {
                    .open-ezicut {
                        ._inline
                    }

                    .product-grade {
                        text-align: center;

                        &> div {
                            ._inline;
                            background-color:@red;
                            color:white;
                            padding: 0 5px;
                            font-weight: 400;

                        }

                        // Grade 1 : orange
                        &.grade-1 {
                            & > div {
                                background-color:@orange;
                            }
                        }

                        // Grade 2 et 3 : green
                        &.grade-3,
                        &.grade-2 {
                            & > div {
                                background-color:@green;
                            }
                        }
                    }
                }

                // Availability
                &.availability {
                    padding:8px 0;

                    span.available {
                        ._inline;
                        height:18px;
                        width:18px;
                        ._border-radius(50%);
                        vertical-align: middle;
                    }
                }

                // Add to cart
                &.add-to-cart {
                    padding:0;
                    text-align:center;

                    input {
                        height:33px;
                        width:60px;
                        font-size:1.4rem;
                        line-height:inherit;
                    }

                    button {
                        height:33px;
                        font-size:1.4rem;
                        text-transform:none;
                        padding:0 15px;
                        ._btn(@blue-da,@blue);

                        &.added-to-cart {
                            ._btn(@green);
                        }

                        i {
                            &:before {
                                ._reset;
                            }
                        }
                    }
                }
            }

            .cc-txt-center {
                text-align:center;
            }
        }
    }


    // Tablet rules
    ._tablet({

        // STRUCTURE PAGE
        .wrap-search-modules {
            flex-wrap: wrap;

            // Search by reference produits
            .form-ref-products {
                order: -1;
                flex:0 0 100%;
                margin-left:0px;
                border-left-width:0px;
                border-bottom:1px solid #a8a8a8;
                padding-left:0px;
                padding-bottom:30px;
                margin-bottom:30px;
                  .products-reference{
                    flex: 0 0 62%;
                  }
                  .catalog-tools {
                    flex: 0 0 32%;
                  }
            }

            // Search by type usinage, groupe matuère, selects, Actions btns
            .form-operation-matter {
                flex:0 0 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                padding-top:0;
            }
        }

        #form-search {
            // Pay with Paypal
            .cc-inside {
                position:relative;

                .contain-paypal-push {

                    top:-80px;
                }
            }

            // Sub Filters after 3 main
            .sub-filters {
                .form-item {
                    padding:10px 5px;
                    margin:0 0px 30px;

                    // Common to all sub-filters
                    select {
                        width:60px;
                    }

                    .form-substratum {

                    }

                    .form-attachement {

                    }
                }
            }
        }

        #product-lists table {
            display:block;
            width:100%;
            max-width:100%;
            overflow-x:auto;
        }
    });

    // Phone rules
    ._phone({
        h1 {
            margin-bottom:30px;
            padding-bottom:10px;
        }

        // STRUCTURE PAGE
        .wrap-search-modules {
            // Search by type usinage, groupe matuère, selects, Actions btns
            .form-operation-matter {
                .type-usinage,
                .groupe-matiere,
                .products-reference {
                    flex:0 0 100%;
                    margin-bottom:20px;
                }
            }
        }

        #form-search {

            // Pay with paypal
            .cc-inside {
                position:relative;

                .contain-paypal-push {
                    padding:5px 10px;

                    em {
                        display: none;
                    }

                    img {
                        margin-left:0px;
                    }
                }
            }

            .main-filters {
                .description {
                    height:inherit;
                }
            }

            .sub-filters {
                .form-item:not(.form-actions) {
                    width:100%;
                    display:block;
                    margin:0 auto 20px;
                    text-align: center;
                }
            }

            .form-actions {
                a {
                    margin:0 auto;
                }

                input {
                    display:block;
                    font-weight:400;
                    width:100%;
                    margin-bottom:20px;
                }
            }
        }

        #product-lists table {
            thead {
                display:none;
            }

            tbody {
                ._flex-display;
                ._flex-direction(column);
                ._flex(1 0 100%);


                // Magic with CSSUA.js library
                .ua-safari &,
                .ua-mobile-iphone & {
                    display:block;
                }


                tr {
                    ._flex-display;
                    ._flex-direction(column);
                    width:100%;
                    margin:0;
                    padding:15px 0;
                    border-top:2px dashed #a8a8a8;
                    background-color:#fafafa;


                    // Magic with CSSUA.js library
                    .ua-safari &,
                    .ua-mobile-iphone & {
                        display:block;
                    }


                    td {
                        ._reset;
                        background-color:white;
                        font-weight:400;
                        ._flex(1 0 100%);


                        // Magic with CSSUA.js library
                        .ua-safari &,
                        .ua-mobile-iphone & {
                            display:block;
                            min-height:29px;
                            ._clearfix;
                        }

                        &.width330 {
                            width:100%;
                            min-width:100%;
                        }

                        &.cc-txt-center {
                            display: flex;
                        }


                        // Span caracteristics
                        &:nth-child(2n) {
                            span {
                                &.cc-s-only,
                                &.value {
                                    background-color:#f2faff
                                }
                            }
                        }
                        span {
                            font-size:1.10em;

                            &.cc-s-only {
                                ._inline;
                                width:50.00%;
                                text-align:right;
                                padding:3px 10px;
                                font-weight:700;
                            }
                            &.value {
                                ._inline;
                                width:50.00%;
                                margin-left:auto;
                                text-align:left;
                                padding:3px 10px;
                            }
                        }

                        // Specific td
                        &.images {
                            text-align:center;
                            padding:0 15px 10px;

                            img {
                                ._inline;
                                margin:0 15px;
                            }
                        }

                        &.title {
                            ._flex-order(-2);
                            padding:10px 15px 10px;
                            text-align:center;
                            font-size:1.15em;
                            font-weight:700;

                            a {
                                padding:0 10px;
                            }
                        }

                        &.reference {
                            text-align:center;
                            display: flex;
                        }

                        &.availability {
                            text-align:left;

                            span.cc-s-only {
                                height:28px;
                            }

                            span.available {
                                width:50.000%;
                                height:28px;
                                ._border-radius(0px);
                                vertical-align: top;

                                em {
                                    display:block;
                                    height:28px;
                                    line-height:28px;
                                    color:white;
                                    font-style:normal;
                                    padding-left:10px;
                                }
                            }
                        }

                        &.price {
                            color:@text-mc;
                            font-weight: 700;
                            font-size:0.9em;

                            .value {
                                color:@blue;
                            }
                        }

                        &.add-to-cart {
                            min-width:100%;
                            margin-top:10px;

                            button,
                            input {
                                ._reset;
                                width:50.00%;
                                text-align:center;
                                ._border-radius(0px);
                                ._box-shadow('none');
                            }
                        }
                    }
                }
            }
        }
    });
}
