/* ****** */
/** TABS **/
/* ****** */

/* ********************* */

/*
    - General tabs


    <ul class="tabs">
        <li>...</li>
    </ul>
*/

.tabs {
    ._reset;
    ._flex-display;
    ._flex-wrap(wrap);
    line-height:@text-lh*1.5;


    &.cc {
        &-underlined {
            border-bottom:1px solid @gray-li;
        }
        &-centered {
            ._flex-display;
            ._flex-justify(center);
        }
        &-left {
            ._flex-justify(flex-start);
        }
        &-right {
            ._flex-justify(flex-end);
        }
        &-stretch {
            ._flex-justify(center);

            &>li {
                ._flex(1 0 auto);
                text-align:center;
            }
        }
        &-bordered {
            border-bottom:1px solid @gray-li;

            &>li {

                &:hover,
                &.active {
                    a:not(.btn) {
                        border-width:1px;
                        border-style:solid;
                        border-color: @primary @primary white @primary;
                        ._border-radius(3px 3px 0 0);
                    }
                }
            }
        }
        &-pills {
            border:0;

            &.cc-unchained {
                &>li {
                    margin-left:1.5em;

                    &:last-child,
                    &:nth-child(1) {
                        a:not(.btn) {
                            ._border-radius(3px);
                        }

                        &.active,
                        &:hover {
                            a:not(.btn) {
                                border-left-color:@primary;
                            }
                        }
                    }
                    &:nth-child(1) {
                        margin-left:0;
                    }

                    a:not(.btn) {
                        border:1px solid @gray-li;
                        ._border-radius(3px);
                    }

                    &.active,
                    &:hover {
                        a:not(.btn) {
                            border:1px solid @primary;
                        }
                    }
                }
            }

            /* Centered pills */
            &.cc-centered {
                ._flex-display;
                ._flex-justify(center);
            }

            &>li {
                margin:0;
                position:relative;

                &:nth-child(1){

                    &.active,
                    &:hover {
                        a:not(.btn) {
                            border-left-color:@gray-li;
                        }
                    }

                    a:not(.btn) {
                        ._border-radius(3px 0 0 3px);
                    }

                    // Right
                    &.cc-last {
                        a:not(.btn) {
                            ._border-radius(3px);
                        }
                    }
                }

                &:last-child {
                    a:not(.btn) {
                        ._border-radius(0 3px 3px 0);
                        border-right-width:1px;
                    }

                    // Right
                    &.cc-last {
                        a:not(.btn) {
                            ._border-radius(3px);
                        }
                    }
                }


                a:not(.btn) {
                    top:0px;
                    border:1px solid @gray-li;
                    border-right-width:0px;
                }

                &.active,
                &:hover {
                    a:not(.btn) {
                        background-color:@primary;
                        color:white;
                        border-top:1px solid @primary;
                        border-bottom:1px solid @primary;
                        border-left-color:white;
                    }
                }
            }
        }
        &-fat {
            line-height:@text-lh*2.5;
        }
        &-thin {
            line-height:@text-lh;
        }
    }

    &>li {
        list-style:none;
        margin-left:1.5em;

        /* Delete margin on first child */
        &:nth-child(1){margin-left:0}

        a:not(.btn) {
            display:block;
            padding:0 0.8em;
            border:1px solid transparent;
            position:relative;
            top:1px;
            ._transition;
        }

        a.btn,
        button {
            padding:0 0.8em;
        }

        /* Active child */
        &:hover,
        &.active {
            a:not(.btn) {
                color:@primary;
                border-bottom-color:@primary;
            }
        }

        // Pulled right item
        &.cc-last {
            ._flex-order(1);
            margin-left:auto;

            .cc-pills& a {
                border-width:1px;
                ._border-radius(3px);
            }
        }
    }


    /* Tablet rules */
    ._tablet({

        /* H alignments */
        &.cc {
            /* Stretch on tablet */
            &-stretch-t {
                ._flex-justify(center);

                &>li {
                    ._flex(1 0 auto);
                    text-align:center;
                }
            }

            /* Left on tablet */
            &-left-t {
                ._flex-justify(flex-start);
            }

            /* Right on tablet */
            &-right-t {
                ._flex-justify(flex-end);
            }

            /* Centered on tablet */
            &-centered-t {
                ._flex-justify(center);
            }
        }
    });


    /* Phone rules */
    ._phone({
        ._flex-direction(column);
        border-bottom-width:0px;

        &.cc {
            &-underlined {
                border-bottom-width:0px;
            }
            &-centered {
                li {
                    text-align:center;
                }
            }
            &-right {
                li {
                    text-align:right;
                }
            }

            &-bordered {
                border-bottom-width:0px;
                &>li {
                    border:1px solid transparent;

                    a:not(.btn) {
                        border:0 !important;
                    }

                    &:hover,
                    &.active {
                        border-color:@primary transparent transparent;

                        &+li {
                            border-color:@primary transparent transparent;
                        }
                    }

                    &:last-child {
                        &:hover,
                        &.active {
                            border-color:@primary transparent;
                        }
                    }
                }
            }

            &-pills {
                &>li {
                    a:not(.btn){
                        border-width:0px 1px 1px;
                        ._border-radius(0px);
                    }

                    &:nth-child(1) {
                        a:not(.btn){
                            border-top-width:1px;
                            ._border-radius(3px 3px 0px 0px);
                        }

                        &.active,
                        &:hover {
                            a:not(.btn){
                                border-top-width:1px;
                                border-left-color:@primary;
                            }
                        }
                    }
                    &:last-child {
                        a:not(.btn){
                            ._border-radius(0 0 3px 3px);
                        }
                    }

                    &.active,
                    &:hover {
                        a:not(.btn){
                            border-top-width:0px;
                            border-color:@primary;
                        }
                    }
                }

                &.cc-unchained {
                    &>li {
                        margin-top:1em;

                        &:nth-child(1) {
                            margin-top:0;
                        }
                    }

                }
            }
        }

        &>li {
            margin-left:0!important;
            ._flex(1 0 100%);

            a:not(.btn) {
                top:0px;
            }

            &.cc-last {
                ._flex-order(0);
            }
        }
    });
}
