// Login and forgot password pages coming from JetStream
.cc-layout-jetstream-guest {
    text-align:center;
    //margin-top:30px;
    margin-bottom:30px;

    .breadcrumb {
        margin: 15px 0 20px;
        text-align: left;
    }
}
// Registration && account page Eshop
.eshop.registration {
    // Company and discount level
    // User tabs when logged
    .user-tabs {
        ._reset;
        margin-bottom: 50px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        border-bottom: 1px solid #e0e0e0;
        li {
            list-style: none;
            a {
                display: block;
                padding: 0 20px;
                line-height: 4rem;
                color: #9e9e9e;
                ._transition;
                &.active,
                &:hover {
                    background-color: @blue;
                    color: white;
                }
            }
        }
    }
    .tab-content {
        display: none;
        margin-bottom: 50px;
        padding-bottom: 50px;
        border-bottom: 3px dashed #a9a9a9;
        // Show tab content
        &.tab-active {
            display: block;
        }
        // H2 titles
        h2 {
            text-align: left;
        }
        // Common to saved carts && order history
        table {
            th,
            td {
                text-align: center;
                &.cc-txt-right {
                    text-align: right;
                }
            }
            tbody tr {
                &.current-line {
                    td {
                        background-color: @blue;
                        color: white;
                        .badge {
                            background-color: white;
                            color: @blue;
                        }
                        a.delete {
                            color: white;
                        }
                    }
                }
                &.odd {
                    &>td {
                        padding: 10px 0;
                    }
                    th,
                    td {
                        text-align: left;
                        &.cc-txt-center {
                            text-align: center;
                        }
                    }
                    table {
                        ._reset;
                    }
                }
                td {
                    font-size: 0.9em;
                    // Nom du panier
                    &.cart-name {
                        font-weight: 700;
                    }
                    a {
                        font-size: 1em;
                        margin-left: 10px;
                        em {
                            font-style: normal;
                        }
                        &.delete {
                            display: block;
                            margin: 0 auto;
                            height: 40px;
                            width: 40px;
                            line-height: 40px;
                            text-align: center;
                            color: @red;
                            font-size: 1.4em;
                            ._border-radius(100%);
                            ._transition;
                            &:hover {
                                color: white;
                                background-color: @red;
                            }
                            i:before {
                                ._reset;
                            }
                        }
                    }
                }
            }
        }
        // Specific to orders history
        .reuse-all-order {
            font-size: 2rem;
            i {
                margin-right: 5px;
            }
        }
        .or {
            padding: 20px 0;
            font-size: 2rem;
            text-transform: uppercase;
            position: relative;
            color: #555555;
            &:before {
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                content: ' ';
                height: 1px;
                width: 100%;
                background-color: #cccccc;
                z-index: 10;
            }
            span {
                display: inline-block;
                position: relative;
                z-index: 10;
                background-color: #f0f0f0;
                font-weight: 600;
                line-height: 2.5rem;
                padding: 0 15px;
            }
        }
        // Order history table products
        table tr.order-detail {
            & > td {
                padding: 0;
                &.price {
                    color: @blue;
                    font-size: 1.05em;
                }
                .wrap-order-detail {
                    padding: 20px;
                    background-color: #f0f0f0;
                    tr:hover {
                        td {
                            background-color: white
                        }
                    }
                    span.available {
                        ._inline;
                        height: 18px;
                        width: 18px;
                        ._border-radius(50%);
                        vertical-align: middle;
                    }
                    .add-to-cart {
                        text-align: center;
                        min-width: 150px;
                        max-width: 150px;
                        width: 150px;
                        input {
                            height: 33px;
                            width: 60px;
                            font-size: 1.4rem;
                            line-height: inherit;
                        }
                        button {
                            height: 33px;
                            font-size: 1.4rem;
                            text-transform: none;
                            padding: 0 15px;
                            ._btn(@blue-da, @blue);
                            i {
                                &:before {
                                    ._reset;
                                }
                            }
                        }
                    }
                }
            }
        }
        // !End order-detail table
        .offers-toggle-products {
            cursor: pointer;
        }
    }

    // Form Edit/New user
    .wrap-user-form {

        // Specific for Change password form
        &.form-pass {
            margin-top: 40px;
            border-top: 3px dashed #eee;
        }

        .registration-text-into {
            text-align:center;
            color:lighten(@black,40%);
        }

        .user-infos {
            margin: 20px auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            //text-align: center;
            padding-bottom: 20px;
            border-bottom: 3px dashed #EEEEEE;
            p {
                line-height: 4rem;
            }
            span {
                padding: 5px;
                ._border-radius(3px);
            }
        }


        form {
            //max-width: 400px;

            label.required:after {
                content: "*";
            }

            & > div {
                margin-bottom: 15px;
            }

            .form-items-wrapper {
                margin: 20px auto;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .required-field-info {
                    color:lighten(@black,40%);
                    flex: 0 0 100%;
                }

                .form-item,
                .form-group {
                    flex: 0 0 48%;
                    position: relative;
                    margin: 0;
                    padding-top:10px;
                        &.form-actions {
                        margin-top: 30px;
                    }
                    &.new-address {
                        flex: 0 0 100%;
                    }
                    .g-recaptcha {
                        margin-top: 20px;
                    }
                }
            }

            .addresses {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-bottom: 0px;
                .address {
                    margin: 0px 10px 20px;
                }
            }

            .form-consent {

            }

            .form-actions {
                display: flex;
                justify-content: center;
                margin-top: 30px;
                text-align: right;
                width: 100%;
            }


            // All inputs but radios
            input:not([type="radio"]) {
                display: block;
                width: 100%;
                height: 50px;
                ._transition(0.1s);
                &:focus {
                    border-bottom: 3px solid @blue;
                }
            }
            select {
                width: 100%;
            }
            label {
                ._reset;
                line-height: 50px;
                font-size: 1.1em;
                color: @text-mc;
                padding-left: 0;
            }
            button {
                display: block;
                padding: 7px 10px;
            }
        }

        // Radios : compte client / Compte invité
        .wrap-form-radios {

            label {
                ._translate(0px);
                color:@text-mc;
                padding-left: 0;
            }

            .form-radio {
                margin-right: 0px;
                margin-left: 35px;

                &.item-first {
                    margin-left: 0px;
                }

                div {
                    display: flex;
                    align-items: center;

                    label {
                        order:1;
                        line-height: 20px;
                    }

                    input  {
                        order: 0;
                        margin-right: 10px;
                        top: -2px;
                    }
                }
            }
        }
    }


    ._tablet({
        .tab-content {
            table {
                tfoot td, thead th {
                    font-size: 0.9em
                }
                tbody tr {
                    td {
                        a {
                            em {
                                display: none;
                            }
                            i:before, &:before {
                                ._reset;
                            }
                        }
                    }
                }
            }
        }
            // Form Edit/New user
        .wrap-user-form {

            form {

            }
        }

    });


    ._phone({
        .phone-mt-10 {
            margin-top: 10px;
        }

        .wrap-user-form {
            form {
                .form-items-wrapper .form-item {
                    flex: 0 0 100%;
                }

                .form-actions button {
                    flex: 0 0 100%;
                }
            }

            // Radios : compte client / Compte invité
            .wrap-form-radios {
                .form-radio {
                    display: block;
                    margin: 15px 0 0px 0;


                    &.item-first {
                        margin-top: 0px;
                    }
                }
            }
        }
        .tab-content {
            h2 {
                text-align: center;
            }
            table {
                thead, tfoot {
                    display: none;
                }
                tbody tr {
                    td {
                        &.cart-actions {
                            text-align: center;
                        }
                        a {
                            margin: 5px;
                            i:before, &:before {
                                ._reset;
                            }
                        }
                    }
                }
            }
            // Specific to order history
            .reuse-all-order {
                font-size: 1.6rem;
                margin: 10px 0;
            }
            &#tab-order-history {
                tbody {
                    ._flex-display;
                    ._flex-direction(column);
                    ._flex(1 0 100%);
                    // Magic with CSSUA.js library
                    .ua-safari &, .ua-mobile-iphone & {
                        display: block;
                    }
                    tr {
                        ._flex-display;
                        ._flex-direction(column);
                        width: 100%;
                        margin: 0;
                        padding: 15px 0;
                        border-top: 2px dashed #a8a8a8;
                        background-color: #fafafa;
                        // Magic with CSSUA.js library
                        .ua-safari &, .ua-mobile-iphone & {
                            display: block;
                        }
                        &.order-detail {
                            border-top: 0;
                            background-color: white;
                            tr {
                                background-color: #f0f0f0;
                                td {
                                    font-size: 1.1em;
                                }
                            }
                        }
                        td {
                            ._reset;
                            background-color: white;
                            font-weight: 400;
                            ._flex(1 0 100%);
                            // Magic with CSSUA.js library
                            .ua-safari &, .ua-mobile-iphone & {
                                display: block;
                                min-height: 29px;
                                ._clearfix;
                            }
                            &:nth-child(2n) {
                                span {
                                    &.cc-s-only, &.value {
                                        background-color: #f2faff
                                    }
                                }
                            }
                            span {
                                &.cc-s-only {
                                    ._inline;
                                    width: 50.00%;
                                    text-align: right;
                                    padding: 3px 10px;
                                    font-weight: 700;
                                }
                                &.value {
                                    ._inline;
                                    width: 50.00%;
                                    margin-left: auto;
                                    text-align: left;
                                    padding: 3px 10px;
                                }
                                &.badge {
                                    font-size: 0.8em;
                                }
                            }
                            &.price {
                                color: @text-mc;
                                font-weight: 700;
                                font-size: 0.9em;
                                .value {
                                    color: @blue;
                                }
                            }
                            &.availability {
                                text-align: left;
                                span.cc-s-only {
                                    height: 28px;
                                }
                                span.available {
                                    width: 50.000%;
                                    height: 28px;
                                    ._border-radius(0px);
                                    vertical-align: top;
                                    em {
                                        display: block;
                                        height: 28px;
                                        line-height: 28px;
                                        color: white;
                                        font-style: normal;
                                        padding-left: 10px;
                                    }
                                }
                            }
                            &.add-to-cart {
                                min-width: 100%;
                                margin-top: 10px;
                                button, input {
                                    ._reset;
                                    width: 50.00%;
                                    text-align: center;
                                    ._border-radius(0px);
                                    ._box-shadow('none');
                                }
                            }
                        }
                    }
                }
            }
        }
    });
}
