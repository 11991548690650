// Eshop : Cart success
.eshop.cart-success {

    .alert-success {
        font-size:2rem;

    }

    p.cc-txt-center {
        ._phone({
            a {
                display:block;
                width:100%;

                &:nth-child(1) {
                    padding-right:0;
                    margin-bottom:20px;
                }
            }

        });
    }

}
