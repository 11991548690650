/*
    *   Admin bar
    *   Containers width
    *   Titles
    *   Breadcrumb
    *   Body on CMS pages and others
    *   Loading animation
    *   Pagination on listing pages (shop, news)
    *   CMS PAGES (common elements)
    *   Checkbox Consent on forms
*/
// Admin bar
.wrap-admin-edit-link {
    position:fixed;
    left:0;
    right:0;
    bottom:0;
    z-index:9999;
    background-color:rgba(0,0,0,0.7);
    padding:10px 0;
    text-align:center;

    a {
        ._reset;
        color:white;
        ._inline;
        color:white;
        padding:0px 20px;
        line-height:40px;
        ._border-radius(3px);
        ._btn(#961010);
        ._transition;

        &:before {
            ._io('\f37e');
            margin-right:15px;
            font-size:1em;
            color:white;
        }
    }
}


// Defined containers width
.cc-inside {
    ._inside(1200px);

    ._tablet({
        ._inside(728px);
    });

    ._phone({
        ._inside(90%);
    });
}
.cc-full-width {
  ._inside(95%);
}


// Defined titles
h1 {
    font-size: 2.8rem;
    text-align: center;
    line-height: 1.2;
    color:@text-mc;
    text-transform: uppercase;

    &.simple {
        text-align: center;
        color:#222222;
        text-transform: uppercase;
        margin-bottom:  40px;

        &:after {
            display: none;
            content:' ';
        }
    }

    // Specific for homepage : title is written in blue !
    .homepage & {
        color:@blue-da;
    }

    &:after {
        display: block;
        content:' ';
        width:75px;
        height:4px;
        background-color:@blue;
        margin:15px auto 40px;
    }
}

h2 {
    // Eshop : "Rechercher des produits", "Tableau des produits"
    &.simple {
        text-align: center;
        color:#222222;
        text-transform: uppercase;
        margin-bottom:  40px;
    }
}




// Breadcrumb
.breadcrumb {
    ._reset;
    margin:20px 0 0;
    background-color:white;


    li {
        list-style:none;
        ._inline;
        padding-right:15px;

        a,span {
            ._inline;
            font-size:1.2rem;
            color:#5c5c5c;
            ._transition;
        }

        a:hover {
            color:@blue-da;
        }

        &:not(:nth-child(1)):before {
            ._fa('\f0da',0.7em,#5c5c5c);
            ._inline;
            margin-right:15px;
        }
    }

    .eshop & {
        margin:15px 0 20px;
    }
}

// body CMS pages
// Generate Images width for wysiwyg render
._generateImgWidth(@iterator:10) when(@iterator <= 100) {
    .body img {
        &.w@{iterator} {
            width:unit(@iterator,%);
            max-width:unit(@iterator,%);
            min-width:unit(@iterator,%);
        }
    }
    ._generateImgWidth(@iterator + 10);
}
._generateImgWidth; // Launch _generateImgWidth
.body {
    text-align:justify;

    h2 {
        ._reset;
        text-align:left;
        font-size:1.15em;
        line-height:1.2;
        color:@blue;
        text-transform:none;
    }

    h3 {
        ._reset;
        text-align:left;
        font-size:1em;
        line-height:1.2;
        text-transform:none;
    }

    img {
        ._inline;
        max-width:40%;
        height:auto;

        &.left {
            display:block;
            float:left;
            margin-right:40px;
            margin-bottom:20px;
        }

        &.right {
            display:block;
            float:right;
            margin-left:40px;
            margin-bottom:20px;
        }

        &.block {
            display:block;
            min-width:100%;
            max-width:100%;
            width:100%;
            margin:0 auto;
        }

        &.centered {
            display:block;
            margin:0 auto;
            max-width:50%;
        }

        &.image_resized {
            max-width:100%;
            width: inherit !important;
        }
    }

    figure {
        &.image {
            margin:0;
            img {
                display:block;
                margin:0 auto;
                max-width:100%;
            }
        }

        &.image-style-side {
            img {
                float:right;
            }
        }
    }

    a {
        color:@blue;

        text-decoration:none;

        &.btn {
            ._inline;
            text-decoration:none;
            color:white;
            padding:0.25em 0.8em;
            line-height:1.8;
            text-align:center;
            vertical-align:middle;
            ._border-radius(unit(3,px));
            ._transition;
            border:1px solid @blue;
            ._btn(@blue);


            &:focus,
            &:active,
            &:visited {
                outline:none;
            }
        }
    }

    ul:not(.slides) {
        li {
            list-style:none;
            position:relative;

            &:before {
                ._fa('\f111',0.5em,@blue);
                position:absolute;
                left:-15px;
                top:7px;
            }
        }
    }

    blockquote {
        padding:0 0 0 30px;
        color:#535252;
        font-size:1em;
        border-left-color:@blue;
    }

    hr {
        height:1px;
        border:0;
        background-color:@blue;
        margin:20px 0;
    }


    table {
        margin-bottom:0;
        border:0;
        width:100% !important;

        thead {
            td,th {
                background-color:@blue;
                color:white;
                font-size:1.18em;
            }
        }

        td {
            background-color:white;
            border-bottom:1px solid #a8a8a8;
            color:#5c5c5c;
            vertical-align:top;

            p,div {
                color:#5c5c5c;
            }
        }

        tr {
            &:nth-child(2n) {
                td {background-color:#f4f4f4}
            }

            &:last-child td {
                border-bottom:4px solid @blue;
            }
        }

        // Basic tables
        &.no-lines {
            thead,
            tbody,
            tfoot {
                th,td {
                    padding:5px;
                    border:0;
                    background-color:transparent !important;

                    img {
                        max-width:100%;

                        &.centered {
                            display:block;
                            margin:0 auto;
                        }
                    }
                }
            }
        }
    }

    ._phone({
        img {
            max-width:100%;
            height:auto;

            &.right,
            &.left {
                float:none;
                clear:both;
                margin:20px auto;
            }
        }

        table {
            display:block;
            overflow-x:auto;
            width:100%;

            img {
                min-width:100% !important
            }
        }

        ul:not(.slides) {
            li {
                &:before {
                    top:8px;
                }
            }
        }

    });
}


// Loading animation
div.loading {
    padding:20px 0;
    font-size:1.1em;
    color:#bbbbbb;
    font-weight:700;
    text-align:center;

    &.with-overlay {
        position: absolute;
        background: #fff;
        width: 100%;
        z-index: 999;
        opacity: .9;
    }
}
.sk-folding-cube {
    margin: 40px auto 0;
    width: 40px;
    height: 40px;
    position: relative;
    ._rotate(45deg, 'Z');

    .sk-cube {
        float: left;
        width: 50%;
        height: 50%;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: @blue;
            ._animLoader;
        }
    }

    .sk-cube2 {
        ._rotate(90deg,'Z');

        &:before {
            -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
            background-color: @blue-da;
        }
    }
    .sk-cube3 {
        ._rotate(180deg,'Z');

        &:before {
            -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
        }
    }
    .sk-cube4 {
        ._rotate(270deg,'Z');

        &:before {
            -webkit-animation-delay: 0.9s;
            animation-delay: 0.9s;
            background-color: @blue-da;
        }
    }
}


// PAGINATION on listing pages
ul.pagination {
    ._reset;
    width:100%;
    margin-top:60px;
    ._flex-display;

    &>li {
        margin:0 4px;
        list-style:none;

        /* general style */
        span,a {
            display:block;
            height:40px;
            width:40px;
            background-color:white;
            text-align:center;
            line-height:40px;
            ._transition;

            .sr-only {
                display:none;
            }
        }

        /* hover & current */
        &.active span,
        a:hover {
            background-color:@blue;
            color:white;
        }

        /* ellipsis */
        &.disabled {
            span {
                background-color:transparent
            }
        }

        /* FIRST LINKS */
        &.last,
        &.next,
        &.first,
        &.prev {
            margin:0;
            a,span {
                width:135px;
                ._border-radius(0px);
                font-size:0.87em;
            }

            a {
                background-color:@blue;
                color:white;
            }

        }

        &.first,
        &.prev {
            margin-right:auto;
        }

        /* LAST LINKS */
        &.last,
        &.next {
            margin-left:auto;

            i:before {
                margin-left:10px;
                margin-right:0;
            }
        }
    }

    ._phone({
        &>li {
            display:none;

            &.prev,
            &.next {
                ._inline;

                width:50.00%;

                a,span {
                    width:100%;
                }
            }
        }

    });
}

// To prevent the results of select2 is hidden by modal we set z-index to 100
span.select2-container {
    z-index: 100;
}


// CMS PAGES
.cms-header {
    height:250px;

    ._media-m-up({
        height:300px;
    });
}

.wrap-cms-intro {
    padding : 50px 0;

    .cms-intro {
        font-size: 1.9rem;
        color:black;
        text-align: center;
        max-width:900px;
        margin: 0 auto;
    }

    .cms & {
        padding-bottom: 0;
    }
}


// SONATA ALERTS
.alert.alert-sonata_flash_success {
    background-color: @alert-su-bg;
    border-color: @alert-su-bc;
    color:@alert-su-tc;

    p,div,ul,
    button.close,
    a {
        color:@alert-su-tc;
    }
}

// News listing
.wrap-news-listing {

    @media screen and (min-width:768px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .news-item {
            max-width:300px;
            margin: inherit;

            .news-img {
                max-width:300px;
            }
        }
    }

    @media screen and (min-width:1201px) {
        justify-content: flex-start;

        .news-item {
            flex:0 0 340px;
            max-width: inherit;
            margin:60px 30px 0;

            .news-img {
                max-width:340px;
            }
        }
    }
}
// News push
.news-item {
    text-align:center;
    max-width:360px;
    margin:0 auto;

    .cms.news & {
        margin-top:60px;
    }
    // Img
    .news-img {
        display:block;
        max-width:360px;
        position:relative;

        &:hover {
            .hover {
                ._opacity(1);
                ._scale(1);
                span {
                    ._rotate(0deg);
                }
            }
        }
        img {
            display:block;
            margin:0 auto;
        }
        .hover {
            position:absolute;
            z-index:11;
            top:10%;
            left:10%;
            background:rgba(0,0,0,0.75);
            ._flex-display;
            width:80%;
            height:80%;
            ._opacity(0);
            ._scale(0.5);
            ._transition;

            span {
                display:flex;
                align-items: center;
                justify-content: center;
                margin:auto;
                background-color:@blue;
                width:67px;
                height:67px;
                ._border-radius(100%);
                ._rotate(-45deg);
                color:white;
                ._transition;

                i {
                    color:white;
                    font-size:4.8rem;
                    line-height: 1;

                    &:before {
                        ._reset;
                    }
                }
            }
        }
        .news-date {
            width:73px;
            height:73px;
            background-color:@blue;
            border:4px solid white;
            ._border-radius(50%);
            position:absolute;
            top:-20px;
            left:-13px;
            z-index:12;
            padding-top:4px;
            span {
                color:white;
            }
            hr {
                ._reset;
                height:1px;
                background-color:white;
            }
        }
    }
    // Title
    h4 {
        ._reset;
        font-size:1.26em;
        line-height:1.3em;
        color:@blue;
        font-weight:700;
        padding:20px 0;
        overflow: hidden;
    }
    // Text
    p {
        font-size:0.85em;
        line-height:1.1;
        text-align: justify;
    }
    .see-more {
        ._inline;
        color:@blue;
        font-size:0.84em;
        margin-top:20px;
        &:hover {
            &:after {
                width:100%;
            }
        }
        &:after {
            display:block;
            margin:0 auto;
            content:' ';
            height:2px;
            background-color:@blue;
            width:0%;
            ._transition;
        }
    }
}


//   Checkbox Consent on forms
form .form-item {
    .form-consent {
        display: flex;

        input {
            flex: 0 0 14px;
            width: 14px;
            height:14px !important;
            margin-right: 0px;
            position: relative;
            top: 4px;
        }

        label {
            line-height: 1.2 !important;
            padding-right: 0 !important;
            padding-left: 10px !important;
        }

        // Error input
        &.error,
        &.is-invalid {

            input {
                border-color: lighten(@red,20%);
                background-color: lighten(@red,50%);

                &:before {
                    border-color: @red;
                }
            }

            label {
                color: @red !important;
            }

        }
    }
}

.d-block {
    display: block;
}

form .invalid-feedback {
    display: none;
}

[class*="fa-"].mr-before-0::before {
    margin-right: 0;
}
.fa-stack {
    font-size: 0.55em;

    &.availability-pill {
        font-size: 0.67em;
    }
}
