/* ************* */
/** BLOCKQUOTES **/
/* ************* */

/* ********************* */

blockquote {
    ._reset;
    padding:0.3em 0.3em 0.3em 2em;
    border-left:4px solid @blockquotes-bc;
    font-style:italic;
    margin:1.5em 0;



    &.cc {
        &-quoted {
            position:relative;
            padding-left:3.5em;

            &:before {
                position:absolute;
                left:11px;
                top:9px;
                ._fa('\f10d', inherit);
                color:@blockquotes-ic;
            }
        }

        /* Reverse blockquote : right alignment */
        &-reverse {
            text-align:right;
            padding:0.3em 2em 0.3em 0.3em;
            border-left:0;
            border-right:4px solid @blockquotes-bc;

            &.cc-quoted {
                padding-left:0;
                padding-right:3.5em;

                &:before {
                    left:inherit;
                    right:11px;
                }
            }
        }
    }


    /* Footer into Blockquote */
    footer {
        font-style:normal;
        font-size:80%;
        color:@blockquotes-footer-tc;
        padding-top:1em;

        &:before {
            ._fa('\f105', 100%, @blockquotes-footer-tc);
            margin-right:5px;
        }
    }
}
