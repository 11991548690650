/* ******** */
/** TABLES **/
/* ******** */

/* ********************* */

table {
    width:100%;
    border-collapse: collapse;
    margin:1em 0;

    /* headers & footer default style */
    /* note : "thead td" has no sense but that is the way some WYSIWYG editors generates tables headers */
    tfoot,
    thead {
        td,th {
            font-weight:400;
            font-size:@text-fs;
            padding:@tables-cells-pa;
            border:0;
            border-bottom:1px solid @tables-ending;
            text-align:left;
            vertical-align:top;
            color:@tables-th-mc;
        }
    }
    tfoot {
        td {
            border:0;
        }
    }


    /* Cells default style */
    tbody {
        td {
            padding:@tables-cells-pa;
            border-bottom:1px solid @tables-ending;
            vertical-align:middle;
        }
    }



    // Specific ChuckCSS styles
    &.cc {

        /* Layout fixed tables */
        &-equal-cols {
            table-layout:fixed;
        }

            /* Striped tables : horizontal */
        &-striped {
            tbody tr:nth-child(2n+1) td {
                background-color:@tables-striped-even;
            }
            tbody tr:nth-child(2n) td {
                background-color:@tables-striped-odd;
            }
        }

        /* Striped tables : vertical */
        &-striped-col {
            tbody tr td:nth-child(2n+1) {
                background-color:@tables-striped-even;
            }
            tbody tr td:nth-child(2n) {
                background-color:@tables-striped-odd;
            }
        }

        /* hovered : highlight the hovered line */
        &-hovered {
            tbody tr:hover td {
                background-color:@tables-hover-bg;
            }
        }

        /* Top alignment cells */
        &-valign-top {
            tbody td {
                vertical-align: top;
            }
        }

        /* Bordered table */
        &-bordered {
            tbody,
            tfoot,
            thead {
                td,th {
                    border:1px solid  @tables-ending;
                }
            }
        }

        /* Unbordered tables */
        &-unbordered {
            tfoot,
            tbody,
            thead {
                tr {
                    td,th {
                        border:0;
                    }
                }
            }
        }
    }


    /* RESPONSIVE TABLES */
    ._phone({
        overflow-x:auto;
        width:100%;

        tbody {
            td {
                font-size:@text-fs*9/10;
            }
        }


    });
}
