/* BLOG PAGES :
    * HOMEPAGE
    * ARTICLE DETAIL
*/

// COMMON TO HOMEPAGE & DETAIL ARTICLE
.articles-filters-wrapper {
    display: flex;
    flex-wrap: wrap;

    // Article container
    section {
        flex:1 0 100%;
    }

    // Each article
    article {
        padding:50px 0;
        border-bottom:3px dashed #e9e9e9;
        text-align: center;

        &:nth-child(1) {
            padding-top:0;
        }

        .article-img a {
            display: block;
        }

        .article-content {
            text-align: left;

            & > h2 {
                color: #333333;
                font-size: 2rem;
                margin-top:10px;
                font-weight: 700;
            }

            .article-categories {
                ._reset;
                list-style: none;

                li {
                    display: inline-block;
                    list-style: none;
                    margin-right:10px;

                    span {
                        color:@blue;
                        font-style:italic;
                        font-size:1.4rem;
                        display: block;
                        margin-right:20px;
                    }

                    a {
                        display: block;
                        border-radius: 2px;
                        background-color: @blue;
                        color:white;
                        font-size:1.2rem;
                        padding:0 10px;
                    }
                }
            }

            .article-text {
                padding:40px 0 15px;
            }

            .article-readmore {
                a {
                    color: @blue;
                }
            }
        }
    }

    // Filters
    aside {
        padding-bottom: 20px;
        order:-1;
        flex:1 0 100%;

        button {
            display: block;
            width:100%;
            margin-bottom:30px;
        }

        .wrap-blog-filters {
            display: none;

            .filter {
                margin-bottom:40px;

                // titles : Catégories, Archives...
                .filter-title {
                    background-color:#F8F8F8;
                    padding:12px;
                    text-transform: uppercase;
                    font-size: 1.6rem;
                    font-weight: 700;
                    margin-bottom:10px;
                }

                // Filters list
                ul {
                    ._reset;

                    ul {
                        margin-bottom: 15px;
                        padding-left:20px;

                        a {
                            padding:0 20px;
                        }
                    }
                }

                li {
                    list-style: none;

                    // Years : 2019, 2018...
                    .year-label {
                        padding-left:20px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding:5px 20px;
                        font-size: 2rem;
                        
                        i {
                            font-size: 1.6rem;

                            &:before {
                                margin-right: 0;
                            }
                        }
                        
                    }

                    a {
                        display: block;
                        padding:4px 20px;
                        font-size: 1.8rem;
                        font-weight: 700;

                        &.active,
                        &:hover {
                            color:@blue;
                        }
                    }
                }

                // Spacific for Archives filters
                &.archives {

                    & > ul > li {
                        
                    }

                    ul ul {
                        display: none;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    // Pagination
    ul.pagination {
        margin: 30px 0;
    }
    

    // Screen > 1199px
    ._media-l-up({
        padding-top:40px;

        section {
            padding-right:60px;
            flex:1;
        }

        article {
            display: flex;

            .article-img {
                flex:0 0 300px;
            }

            .article-content {
                flex: 1;
                padding-left: 40px;
            }
        }

        aside {
            order:1;
            flex:0 0 300px;

            button {
                display: none;
            }

            .wrap-blog-filters {
                display: block !important;
            }
        }       
    });
}


// Specific for blog detail page
.blog-detail {

    .cms-intro {
        color: @blue;
        font-size: 1.4rem;
    }

    .articles-filters-wrapper {
        aside {
            order:1;
        }

        section {
            margin-bottom: 40px;

            article {
                border-bottom-width: 0;

                .article-text {
                    padding:0 0 30px 0;
                    border-bottom:3px dashed #e9e9e9;

                    // Main Article Image
                    .main-img {
                        max-height:500px;
                        display: block;
                        margin:0 auto 40px;
                    }
                }

                .article-links {
                    padding-top: 30px;
                    text-align: center;

                    .article-categories {
                        margin-bottom:30px;
                    }

                    .article-share {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;

                        p {
                            flex:1 0 100%;
                        }
                    
                        [rel] {
                            margin:5px 10px;
                            color:white;
                            flex:0 0 30px;
                            max-width: 30px;
                            height: 30px;
                            border-radius:100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &.facebook {
                                background-color:#3b5999;
                            }
                            &.twitter {
                                background-color: #55acee;
                            }

                            i {
                                display: block;

                                &:before {
                                    ._reset;
                                }
                            }
                        }
                    }

                    .article-back {
                        margin-top:40px;

                        a {
                            display: block;
                            width: 100%;
                        }
                    }                 
                }
            }
        }
    }

    

    // Screen > 1199px
    ._media-l-up({
        .articles-filters-wrapper {
            section {
                
                article {

                    .article-text {

                        // Main Article Image
                        .main-img {
                            float:left;
                            max-width:400px;
                            max-height:400px;
                            margin:0 30px 30px 0;
                        }
                    }

                    .article-links {
                        text-align: center;

                        .article-categories {
                            text-align: left;
                        }

                        .article-back {
                            a {
                                display: inline-block;
                                width:inherit;
                            }
                        }                 
                    }
                }
            }
        }
    });
}