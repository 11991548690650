/* Import ChuckCSS sources */
@import '../../../public/lib/ChuckCSS/chuckcss/front.less';
@import 'settings-override';


html.opened-menu {
    overflow:hidden;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    ._helvetica;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color:white;
    letter-spacing: -0.3px;

    h1,h2,h3,h4,h5,h6 {
        ._montserrat;
    }

    &.opened-menu {
        overflow:hidden;
    }

    ._tablet({
        @text-fs-old : 17px;
        @text-fs : 1.7rem;
        font-size: 1.7rem;
    });

    #content {
        background-color:white;
        overflow:hidden;
        position: relative;
        z-index: 1;
    }

    button.cc-loading {
        &:before {
            display:block;
            border:2px solid white;
            border-top-color: transparent;
        }
    }

    .alert {
        font-size:0.84em;
        text-align: center;
    }

    hr {
        ._reset;
        height:3px;
        border-top:3px dashed #a8a8a8;
        margin:20px 0;
    }


    // AFFIX BOOTSTRAP
    &.affix {
        #main-header .wrap-main-menu {
            position: fixed;
            z-index:9;
            top:0;
            left:0%;
            right:0;

            &.shown {
                left:0;
            }

            nav ul.main-site-menu > li {
              /*
                &.sticky-logo {
                    display: inherit;
                }
                */

                /*
                & > a {
                    padding: 14px 25px;
                }
                */
            }
        }

        #content {
            padding-top:80px;
        }

        ._media-l({
            #main-header .wrap-main-menu {
                left:-110%;

                &.shown {
                    left:0;
                }
            }
        });
    }
}

/* define text selection */
::-moz-selection {
    background: @blue-da;
}
::selection {
    background: @blue-da;
}



/* *** HELPERS *** */
@import 'helpers/loader';
@import 'helpers/hamburger';
@import 'helpers/wow';
@import 'helpers/cookies';
@import "helpers/modals";
@import "helpers/tooltips";
@import "helpers/popovers";


/* *** SPECIFIC *** */
@import "website/common";
@import "website/header-footer";
@import "website/homepage";
@import "website/videos";
@import "website/history";
@import "website/team";
@import "website/cms";
@import "website/contact";
@import "website/downloads";
@import "website/gamme";
@import "website/blog";
@import "website/slider-news";
@import "website/auth";
@import "website/generic-landing-page";

/* *** JQUERY PLUGINS *** */
@import "jquery-plugins/flickity.less";
@import "jquery-plugins/datepicker.less";
@import "jquery-plugins/fancybox.less";

// Eshop
@import "website/eshop-homepage";
@import "website/eshop-product-detail";
@import "website/eshop-cart-summary";
@import "website/eshop-cart-success";
@import "website/eshop-registration";
@import "website/eshop-ezicut";
