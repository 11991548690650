#login-container, #forgot-password-container, #reset-password-container {

    width: 380px;
    max-width: 100%;
    margin: 0 auto;

    form {

        .form-item {
            margin:0.8em 0;
        }
        button,
        label,input[type="text"],input[type="password"],input[type="email"] {
            width:100%;
        }

        label {
            text-align:center;
            font-weight:700;
            display:inline;
        }
    }

    .forget-psswd {
        color:#bbbbbb;
        font-size:0.8em;
        text-decoration:underline;
        ._transition;

        &:hover {
            color:@blue;
        }
    }

    ._phone({
        .modal-content {
            width:80%;
        }
    });
}

#login-container,
#forgot-password-container {
    padding: 20px;
}

#reset-password-container {
    padding: 0px 20px 20px 20px;
}