.slider-news{
  position: relative;
  display: flex;
  min-height: calc(~"100vh - 167px");
  background-color: #0062A6;
}
@media (max-width:1199px){
  .slider-news{
    min-height: calc(~"100vh - 145px");
  }
}
.slider-prev{
  position: absolute;
  left:0;
  top: 50%;
  padding: 30px 20px;
  background-color: #042147;
  height:102px;
  margin-top:-51px;
  z-index:1;
  transition: background-color .3s ease-in-out;
  display: inline-flex;
  align-items: center;
}
.slider-prev:hover{
  background-color: #003e67;
}
.slider-next{
  position: absolute;
  right:0;
  top: 50%;
  padding: 30px 20px;
  background-color: #042147;
  height:102px;
  margin-top:-51px;
  z-index:1;
  transition: background-color .3s ease-in-out;
  display: inline-flex;
  align-items: center;
}
.slider-next:hover{
  background-color: #003e67;
}
.slide-news{
  display: flex;
  flex-grow: 1;
  max-width: 100%;
}
.slide-news:not(.active){
  display: none;
}
.slide-news-content{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow:1;
  width:50%;
  max-width: 50%;
  background-color: #0062A6;
  color: #fff;
  padding: calc(~"( 100vh - 167px ) / 5") 120px calc(~"( 100vh - 167px ) / 50") 160px;
  h2{
    color: #fff;
    font-size: 40px;
    text-transform: uppercase;
    padding-bottom: 10px;
  }
  p{
    font-weight: 400;
    padding-bottom: 30px;
  }
  a{
    color: #fff;
    text-decoration: underline;
  }
  a.read-more{
    padding: 10px 20px;
    border: 2px solid #fff;
    color:#fff;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    transition: all .3s ease-in-out;
    margin-bottom: 30px;
    text-decoration: none;
  }
  a.read-more:hover{
    color: #0062A6;
    background-color: #fff;
  }
  .slide-pills{
    margin-top: auto;
    align-self: center;
    .slide-pill{
      display: inline-block;
      width:18px;
      height:18px;
      border-radius: 18px;
      margin: 0 5px;
      background-color: #fff;
    }
    .slide-pill:not(.active){
      opacity: .5;
    }
  }
}
.slide-news-img{
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  flex-grow:1;
  width:50%;
  max-width: 50%;
}
.bg-arrow{
  position: absolute;
  left:0;
  top:0;
  right:80%;
  bottom:0;
  height:100%;
}
@media (max-width:1023px){
  .bg-arrow{
    display: none;
  }
  .slider-news{
    height:auto;
  }
  .slider-prev{
    top:200px;
  }
  .slider-next{
    top:200px;
  }
  .slide-news{
    flex-direction: column-reverse;
    height:auto;
  }
  .slide-news-content{
    height:auto;
    width:100%;
    max-width:100%;
    padding: 40px 80px 40px 80px;
    align-items: center;
    text-align: center;
    h2{
      font-size: 2.8rem;
    }
  }
  .slide-news-img{
    height:500px;
    max-height: 500px;
    width:100%;
    max-width:100%;
  }
}
@media (max-width:767px){
  .slide-news-content{
    padding: 40px 0;
    width: 90%;
    margin: 0 auto;
    overflow-wrap: anywhere;
    h2{
      font-size: 2rem;
    }
    p{

    }
  }
  .slider-prev{
    padding: 15px 10px;
    height:50px;
    img{
      max-width: 10px;
    }
  }
  .slider-next{
    padding: 15px 10px;
    height:50px;
    img{
      max-width: 10px;
    }
  }
  .slide-news-img{
    height:350px;
    max-height: 350px;
  }
}
@media (max-width:400px){
  .slide-news-img{
    height:200px;
    max-height: 200px;
  }
}
