/* CMS PAGES :
    * history : Frise chronologique
*/



// SPECIFIC FOR HISTORY PAGE
.history {
    .history-timeline {
        padding:60px 0;
        border-left:8px solid @blue-da;

        &>.history-item {
            margin-top:45px;

            &:nth-child(1) {
                margin-top:0;
            }

            .history-date {
                position:relative;

                .dot {
                    display:block;
                    height:30px;
                    width:30px;
                    background-color:@blue;
                    border:3px solid white;
                    ._border-radius(100%);
                    position:absolute;
                    top:31px;
                    left:-19px;
                }

                .date {
                    display:block;
                    color:@blue;
                    font-size:1.57em;
                    font-weight:700;
                    border-bottom:4px solid @blue;
                    padding-left:13px;
                    line-height:30px;
                    padding-top:15px;
                }
            }

            .history-img {
                img {
                    display:block;
                    margin:auto
                }
            }

            .history-txt {
                padding-top:30px;

                h2 {
                    color:@blue;
                    text-transform:none;
                    font-size:1em;
                    font-weight:700;
                    margin-bottom:0;
                    text-align:left;
                }

                .body {
                    font-weight: 400;
                    color:black;
                }

                ._media-s-up({
                    padding-top:0;
                });
            }
        }
    }
}
