/*
    * SETTINGS.LESS
    ~ Configuration file

    * HOW TO UNDERSTAND VAR NAMES ?
        "-mc" : main-color
        "-bg" : background-color
        "-bc" : border-color
        "-tc" : text-color
        "-ic" : icon color
        "-su" : success
        "-er" : error
        "-wa" : warning
        "-in" : info
        "-he" : height
        "-lh" : line-height
        "-br" : border-radius
        "-fs" : font-size (fonts only)
        "-li" : light (colors only)
        "-da" : dark (colors only)
        "-na" : name (colors only)
        "-mw" : max-width (media queries)
        "-pa" : padding (for grid columns, and helpers paddings and margins)
        "-ma" : margin (for grid containers, and helpers paddings and margins)
*/


/* ********* */
/* VARIABLES */
/* ********* */

/*
    -- @ Media Queries @ --
*/
@phone-mw : 767px; /* Max-width for phone devices */
@tablet-mw : 1200px; /* Max-width for tablet devices */


/*
    -- @ Colors @ --
*/
/* Primary color */
@primary : #29b7e1;
@primary-na : primary; /* Name of the color, for @aColors array */
@primary-li : lighten(@primary,10%); /* Lighten primary */
@primary-da : darken(@primary,10%); /* Lighten primary */

// Green
@green : #80ae37;
@green-na : green; /* Name of the color, for @aColors array */
@green-li : lighten(@green,10%); /* Lighten green */
@green-da : darken(@green,10%); /* Darken green */

// red
@red :   #c41515;
@red-na : red; /* Name of the color, for @aColors array */
@red-li : lighten(@red,10%); /* Lighten red */
@red-da : darken(@red,10%); /* Darken red */

// gray
@gray : #bababa;
@gray-na : gray; /* Name of the color, for @aColors array */
@gray-li : lighten(@gray,10%); /* Lighten gray */
@gray-da : darken(@gray,10%); /* Darken gray */

// blue
@blue : #3498db;
@blue-na : blue; /* Name of the color, for @aColors array */
@blue-li : lighten(@blue,10%); /* Lighten blue */
@blue-da : darken(@blue,10%); /* Darken blue */

// purple
@purple : #8e44ad;
@purple-na : purple; /* Name of the color, for @aColors array */
@purple-li : lighten(@purple,10%); /* Lighten purple */
@purple-da : darken(@purple,10%); /* Darken purple */

// orange
@orange : #f39c12;
@orange-na : orange; /* Name of the color, for @aColors array */
@orange-li : lighten(@orange,10%); /* Lighten orange */
@orange-da : darken(@orange,10%); /* Darken orange */

// yellow
@yellow : #f1c40f;
@yellow-na : yellow; /* Name of the color, for @aColors array */
@yellow-li : lighten(@yellow,10%); /* Lighten yellow */
@yellow-da : darken(@yellow,10%); /* Darken yellow */


// Colors arrays : Had any color \m/
// array(@color1-name @color1-value, @color2-name @color2-value,...);
@aColors :
    @primary-na @primary,
    @green-na @green,
    @red-na @red,
    @blue-na @blue,
    @purple-na @purple,
    @orange-na @orange,
    @yellow-na @yellow;


/*
    -- @ Texts @ --
*/
@text-mc: #3a3a3a;                      /* Texts ~ Main color (hexa, rgb, rgba...) */
@text-fs-old : 15px;                    /* Texts font-size ~ Used for old browsers that doesn't support rem units */
@text-fs: unit((@text-fs-old/10),rem);    /* Textes font-size (applied to <body>) */
@text-lh : 1.5;                         /* Texts line-height : ~= font-size*1.5 */



/*
    -- @ Links @ --
    * HTML
        <a href="#" class="btn">My button link</a>
        <button class="btn">My button</button>
*/
@links-tc : @text-mc;               /* Links ~ Text color */
@links-fs : @text-fs;               /* Links ~ Font-size */
@links-btn-tc : white;              /* Links ~ buttons text color when colorized */
@links-btn-hover-tc : white;        /* Links ~ buttons text color on hover when colorized */
@links-btn-lh : 1.8;                /* Links ~ buttons line-height : ~= font-size*1.8 */
@links-btn-br : 3;                  /* Links ~ buttons border-radius (in px) */
@links-btn-thin-fs : @text-fs*0.7;  /* <a class="btn cc-thin"> font-size */
@links-btn-fat-fs : @text-fs*1.3;   /* <a class="btn cc-fat"> font-size */




/*
    -- @ Titles @ --
    * HTML
        <Hn>My Heading title</Hn>
*/
@title-mc : @text-mc; /* Hn ~ Titles main color */
@title-h1-fs : 2em;
@title-h1-lh : @title-h1-fs*1.1;
@title-h2-fs : 1.8em;
@title-h2-lh : @title-h2-fs*1.1;
@title-h3-fs : 1.6em;
@title-h3-lh : @title-h3-fs*1.15;
@title-h4-fs : 1.4em;
@title-h4-lh : @title-h4-fs*1.15;
@title-h5-fs : 1.3em;
@title-h5-lh : @title-h5-fs*1.15;
@title-h6-fs : 1.2em;
@title-h6-lh : @title-h6-fs*1.15;

@subtitle-mc : lighten(@title-mc,40%);  /* Subtitle font-color */
@subtitle-fs : 70%;                     /* Subtitle font-color */



/*
    -- @ Tables @ --
    * HTML
        <table class="hover colored"> // exemple
            <thead>
                <tr>
                    <th>My Head lind</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Lorem Ipsum</td>
                </tr>
            </tbody>
        </table>
*/
@tables-cells-pa : 8px;         /* <th> & <td> padding */
@tables-ending : #e0e0e0;       /* Line below tables */
@tables-striped-even : #ffffff; /* Even lines bg-color */
@tables-striped-odd : #f0f0f0;  /* Odd lines bg-color */
@tables-hover-bg : #f0f0f0;     /* Hovered lines bg-color (only for table.hovered) */
@tables-th-mc : #9e9e9e;        /* <thead><th> main color */



/*
    -- @ Forms @ --
    * HTML
        Read all exemples in elements/forms.less file
*/
@form-mc : @primary;            /* Primary color for forms elements */

@input-bg : white;              /* Input ~ bg-color */
@input-fs : @text-fs * (9/10);  /* Input ~ font-size = @text-fs (can be changed by custom value) */
@input-lh : @text-fs*2.8;               /* Input ~ line-height (Submit buttons too) */
@input-bc : #ddd;               /* Input border-color */
@input-br : 3;                  /* Input ~ border-radius (in px) */

@submit-bg : @primary;          /* Submit input/button bg-color */

@prefix-bg : @primary;          /* Prefix bgColor */
@prefix-pa : 0 1.2em;           /* Prefix padding */
@prefix-tc : white;             /* Prefix text color */

@suffix-bg : @prefix-bg;        /* Suffix bgColor */
@suffix-pa : @prefix-pa;        /* Suffix padding */
@suffix-tc : @prefix-tc;        /* Suffix text color */



/*
    -- @ Alerts @ --
    * HTML
        <div class="alert alert-error">...</div>
        <div class="alert alert-success">...</div>
*/
@alert-br : 3; /* Alerts border-radius (pixel)
/* success */
@alert-su-bg : #dff0d8;
@alert-su-bc : #d6e9c6;
@alert-su-tc : #468847;
/* error */
@alert-er-bg : #f2dede;
@alert-er-bc : #ebccd1;
@alert-er-tc : #b94a48;
/* warning */
@alert-wa-bg : #fcf8e3;
@alert-wa-bc : #faebcc;
@alert-wa-tc : #c09853;
/* info */
@alert-in-bg : #d9edf7;
@alert-in-bc : #bce8f1;
@alert-in-tc : #3a87ad;



/*
    -- @ Blockquotes @ --
    * HTML
        <blockquote class="quoted">
            <p>A odio tristique cum aliquam, integer? Etiam. A odio tristique cum aliquam, integer? Etiam. A odio tristique cum aliquam, integer? Etiam.A odio tristique cum aliquam, integer? Etiam.</p>
            <footer>
                Someone famous in <cite title="Les hackis pour la vie">Les hackis pour la vie</cite>
            </footer>
        </blockquote>
*/
@blockquotes-bc : @primary;         /* Blockquote ~ border-left color */
@blockquotes-ic : @blockquotes-bc;  /* Blockquote ~ FontAwesome icon color (only for <blockquote.quoted>) */
@blockquotes-footer-tc : @gray;     /* Blockquote ~ footer text-color */



/*
    -- @ Grids @ --
    * HTML
        <div class="columns">
            <div class="column cc-6">6 columns</div>
            <div class="column cc-6">6 columns</div>
        </div>
*/
@cols-gutter : 2em; /* Gutter between 2 cols = Each column margin-left & margin-right */
@cols-total : 12; /* Total of cols in grid */
@cols-pa : 1em; /* Each column padding  */
@cols-ma-top : 0em; /* Each column margin top */
@cols-ma-bottom : @cols-ma-top; /* Each column margin bottom */
@cols-masonry-gap : 1.5em; /* Gap between each column with masonry layout */



/*
    -- @ Helpers @ --
*/
@helper-pa : 1em;      /* Used for helpers cc-pa */
@helper-pat : 1em;     /* Used for helpers cc-pat */
@helper-par : 1em;     /* Used for helpers cc-par */
@helper-pab : 1em;     /* Used for helpers cc-pab */
@helper-pal : 1em;     /* Used for helpers cc-pal */
@helper-ma : 1em;      /* Used for helpers cc-ma */
@helper-mat : 1em;     /* Used for helpers cc-mat */
@helper-mar : 1em;     /* Used for helpers cc-mar */
@helper-mab : 1em;     /* Used for helpers cc-mab */
@helper-mal : 1em;     /* Used for helpers cc-mal */


/*
    -- @ FONTS MIXINS @ --
    "Here define the fonts"

    * LESS call
        .font(){font-family:'My font',sans-serif;}
*/
._helvetica() {font-family: 'Helvetica Neue', sans-serif;}


/*
    -- @ Images @ --
    NOTE : This var is not used in ChuckCSS but i usefull for front-end developments
*/
@imgPath : '../img'; /* Path of images files */


/*
    -- @ FONTS PATH @ --
    "Here define the 'fonts' folder path"
    NOTE : This var is not used in ChuckCSS but i usefull for front-end developments
*/
@fontPath : '../fonts';     /* Path of fonts files */
